import axios from '../../services/axios';

function vote(itemId, variantId, fingerprint) {
  const params = {
    vote: {
      item_id: itemId,
      variant_id: variantId,
      fingerprint,
    },
  };

  return axios({
    method: 'POST',
    url: '/vote/',
    params,
  });
}

function getVotes() {
  return axios({
    method: 'GET',
    url: '/vote/',
  });
}

function loadCampaigns() {
  return axios({
    method: 'GET',
    url: '/the-lab.json/',
  });
}

function upvote(campaignId, fingerprint) {
  return axios({
    method: 'POST',
    url: `/campaigns/${campaignId}/upvote.json`,
    params: {
      fingerprint,
    },
  });
}

function downvote(campaignId, fingerprint) {
  return axios({
    method: 'POST',
    url: `/campaigns/${campaignId}/downvote.json`,
    params: {
      fingerprint,
    },
  });
}

const api = {
  vote,
  getVotes,
  loadCampaigns,
  upvote,
  downvote,
};

export default api;
