import { all } from 'redux-saga/effects';
import bestSellersSaga from '../../homepage/sagas/best_sellers';
import taxonsSaga from './taxons';
import cartSaga from './cart';
import couponCodeSaga from './coupon_code';
import lineItemsSaga from '../../checkout/sagas/line_items';
import orderSaga from '../../checkout/sagas/order';
import productsSaga from '../../products/sagas/products';
import loginSaga from '../../modals/sagas/login';
import modalSaga from '../../modals/sagas/modal';
import reviewsSaga from '../../product/sagas/reviews';
import campaignsSaga from '../../campaigns/sagas/campaigns';
import shopsSaga from '../../shops/sagas';
import userSaga from '../../user/sagas/user';
import freeSamplesSaga from '../../checkout/sagas/free_samples';
import innovatorHighlightsSaga from '../../innovator/sagas';

export default function* rootSaga() {
  yield all([
    bestSellersSaga(),
    taxonsSaga(),
    cartSaga(),
    couponCodeSaga(),
    lineItemsSaga(),
    freeSamplesSaga(),
    orderSaga(),
    productsSaga(),
    loginSaga(),
    modalSaga(),
    reviewsSaga(),
    campaignsSaga(),
    shopsSaga(),
    userSaga(),
    innovatorHighlightsSaga(),
  ]);
}
