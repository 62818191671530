import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  openModal: ['payload'],
  openModalRequest: ['payload'],
  closeModalRequest: ['payload'],
  closeModal: null,
  logoutRequest: null,
  loginRequest: ['payload'],
  registerRequest: ['payload'],
  facebookLoginRequest: null,
  googleLoginRequest: null,
  resendConfirmationEmailRequest: ['payload'],
  resetPasswordRequest: ['payload'],
  getWelcomePromotionRequest: null,
  setError: ['payload'],
  setLoading: ['payload'],
  setData: ['payload'],
}, { prefix: '[MODALS] ' });
