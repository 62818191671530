import { takeEvery, call, put } from 'redux-saga/effects';
import api from '../api';
import { INNOVATOR_HIGHLIGHTS_ACTION_TYPES, innovatorHighlightsActions } from '../actions';

export function* loadMoreInnovatorHighlights(action) {
  function* onRequestComplete(response) {
    yield put(innovatorHighlightsActions.addInnovatorHighlights(response));
    yield put(innovatorHighlightsActions.stopLoading());
    yield put(innovatorHighlightsActions.setCurrentPage(action.payload.currentPage + 1));
  }

  yield put(innovatorHighlightsActions.startLoading());
  const response = yield call(api.loadMoreInnovatorHighlights, action.payload.url);
  yield* onRequestComplete(response.data);
}

export default function* innovatorHighlightsSaga() {
  yield takeEvery(
    INNOVATOR_HIGHLIGHTS_ACTION_TYPES.LOAD_MORE_INNOVATOR_HIGHLIGHTS_REQUEST,
    loadMoreInnovatorHighlights,
  );
}
