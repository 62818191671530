import { INNOVATOR_HIGHLIGHTS_ACTION_TYPES } from '../../actions';

export const initialState = {
  objects: [],
  loading: false,
  page: 0,
  total: 0,
};

function innovatorHighlights(state = initialState, action = {}) {
  switch (action.type) {
    case INNOVATOR_HIGHLIGHTS_ACTION_TYPES.START_LOADING:
      return { ...state, loading: true };
    case INNOVATOR_HIGHLIGHTS_ACTION_TYPES.STOP_LOADING:
      return { ...state, loading: false };
    case INNOVATOR_HIGHLIGHTS_ACTION_TYPES.ADD_INNOVATOR_HIGHLIGHTS:
      return { ...state, objects: Array.from(new Set([...state.objects, ...action.payload])) };
    case INNOVATOR_HIGHLIGHTS_ACTION_TYPES.SET_CURRENT_PAGE:
      return { ...state, page: action.payload };
    case INNOVATOR_HIGHLIGHTS_ACTION_TYPES.SET_INNOVATOR_HIGHLIGHTS_TOTAL:
      return { ...state, total: action.payload };
    default:
      return state;
  }
}

export default innovatorHighlights;
