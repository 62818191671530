import { takeEvery, put } from 'redux-saga/effects';
import { MODALS_ACTION_TYPES, modalsActions } from '../../actions';
import MODALS from '../../constants/modals';
import DATA from '../../constants/data';

export function* closeModal(action) {
  yield put(modalsActions.setError(null));
  yield put(modalsActions.closeModal());

  switch (action.payload) {
    case MODALS.CLOSE_STATUSES.SUCCESSFUL_REGISTRATION:
      yield put(modalsActions.openModalRequest({ modal: MODALS.SIGN_UP_CONFIRMATION }));
      break;
    case MODALS.CLOSE_STATUSES.SUCCESSFUL_REGISTRATION_FOR_VOTING:
      yield put(modalsActions.openModalRequest({ modal: MODALS.SIGN_UP_CONFIRMATION_FOR_VOTING }));
      break;
    case MODALS.CLOSE_STATUSES.SUCCESSFUL_REGISTRATION_FOR_COMMENTING:
      yield put(
        modalsActions.openModalRequest({ modal: MODALS.SIGN_UP_CONFIRMATION_FOR_COMMENTING }),
      );
      break;
    case MODALS.CLOSE_STATUSES.LOGIN_NOT_CONFIRMED:
      yield put(modalsActions.openModalRequest({ modal: MODALS.RESEND_SIGN_UP_CONFIRMATION }));
      break;
    case MODALS.CLOSE_STATUSES.LOGIN_NOT_CONFIRMED_FOR_VOTING:
      yield put(
        modalsActions.openModalRequest({ modal: MODALS.RESEND_SIGN_UP_CONFIRMATION_FOR_VOTING }),
      );
      break;
    case MODALS.CLOSE_STATUSES.LOGIN_NOT_CONFIRMED_FOR_COMMENTING:
      yield put(
        modalsActions.openModalRequest(
          { modal: MODALS.RESEND_SIGN_UP_CONFIRMATION_FOR_COMMENTING },
        ),
      );
      break;
    case MODALS.CLOSE_STATUSES.FORGOT_PASSWORD_SENT:
      yield put(modalsActions.openModalRequest({ modal: MODALS.FORGOT_PASSWORD_SENT }));
      break;
    default:
      break;
  }
}

export function* openModal(action) {
  yield put(modalsActions.setError(null));

  const { modal, data } = action.payload;

  switch (modal) {
    case MODALS.SIGN_UP:
      yield put(modalsActions.setData(DATA.SIGN_UP));
      yield put(modalsActions.openModal(MODALS.SIGN_UP));
      break;
    case MODALS.SIGN_UP_FOR_VOTING:
      yield put(modalsActions.setData(DATA.SIGN_UP_FOR_VOTING));
      yield put(modalsActions.openModal(MODALS.SIGN_UP));
      break;
    case MODALS.SIGN_UP_FOR_WAITLIST:
      yield put(modalsActions.setData({ ...DATA.SIGN_UP_FOR_WAITLIST, ...data }));
      yield put(modalsActions.openModal(MODALS.SIGN_UP));
      break;
    case MODALS.SIGN_UP_FOR_COMMENTING:
      yield put(modalsActions.setData(DATA.SIGN_UP_FOR_COMMENTING));
      yield put(modalsActions.openModal(MODALS.SIGN_UP));
      break;
    case MODALS.SIGN_UP_FOR_INNOVATION:
      yield put(modalsActions.setData(DATA.SIGN_UP_FOR_INNOVATION));
      yield put(modalsActions.openModal(MODALS.SIGN_UP));
      break;
    case MODALS.SIGN_UP_FOR_PRODUCT_SUBSCRIPTION:
      yield put(modalsActions.setData(DATA.SIGN_UP_FOR_PRODUCT_SUBSCRIPTION));
      yield put(modalsActions.openModal(MODALS.SIGN_UP));
      break;
    case MODALS.SIGN_IN:
      yield put(modalsActions.setData(DATA.SIGN_IN));
      yield put(modalsActions.openModal(MODALS.SIGN_IN));
      break;
    case MODALS.SIGN_IN_FOR_VOTING:
      yield put(modalsActions.setData(DATA.SIGN_IN_FOR_VOTING));
      yield put(modalsActions.openModal(MODALS.SIGN_IN));
      break;
    case MODALS.SIGN_IN_FOR_COMMENTING:
      yield put(modalsActions.setData(DATA.SIGN_IN_FOR_COMMENTING));
      yield put(modalsActions.openModal(MODALS.SIGN_IN));
      break;
    case MODALS.SIGN_IN_FOR_PRODUCT_SUBSCRIPTION:
      yield put(modalsActions.setData(DATA.SIGN_IN_FOR_PRODUCT_SUBSCRIPTION));
      yield put(modalsActions.openModal(MODALS.SIGN_IN));
      break;
    case MODALS.FORGOT_PASSWORD:
      yield put(modalsActions.setData(DATA.FORGOT_PASSWORD));
      yield put(modalsActions.openModal(MODALS.FORGOT_PASSWORD));
      break;
    case MODALS.FORGOT_PASSWORD_FOR_VOTING:
      yield put(modalsActions.setData(DATA.FORGOT_PASSWORD_FOR_VOTING));
      yield put(modalsActions.openModal(MODALS.FORGOT_PASSWORD));
      break;
    case MODALS.FORGOT_PASSWORD_FOR_COMMENTING:
      yield put(modalsActions.setData(DATA.FORGOT_PASSWORD_FOR_COMMENTING));
      yield put(modalsActions.openModal(MODALS.FORGOT_PASSWORD));
      break;
    case MODALS.FORGOT_PASSWORD_FOR_PRODUCT_SUBSCRIPTION:
      yield put(modalsActions.setData(DATA.FORGOT_PASSWORD_FOR_PRODUCT_SUBSCRIPTION));
      yield put(modalsActions.openModal(MODALS.FORGOT_PASSWORD));
      break;
    case MODALS.FORGOT_PASSWORD_SENT:
      yield put(modalsActions.setData(DATA.FORGOT_PASSWORD_SENT));
      yield put(modalsActions.openModal(MODALS.FORGOT_PASSWORD_SENT));
      break;
    case MODALS.RESEND_SIGN_UP_CONFIRMATION:
      yield put(modalsActions.setData(DATA.RESEND_SIGN_UP_CONFIRMATION));
      yield put(modalsActions.openModal(MODALS.RESEND_SIGN_UP_CONFIRMATION));
      break;
    case MODALS.RESEND_SIGN_UP_CONFIRMATION_FOR_VOTING:
      yield put(modalsActions.setData(DATA.RESEND_SIGN_UP_CONFIRMATION_FOR_VOTING));
      yield put(modalsActions.openModal(MODALS.RESEND_SIGN_UP_CONFIRMATION));
      break;
    case MODALS.RESEND_SIGN_UP_CONFIRMATION_FOR_COMMENTING:
      yield put(modalsActions.setData(DATA.RESEND_SIGN_UP_CONFIRMATION_FOR_COMMENTING));
      yield put(modalsActions.openModal(MODALS.RESEND_SIGN_UP_CONFIRMATION));
      break;
    case MODALS.SIGN_UP_CONFIRMATION:
      yield put(modalsActions.setData(DATA.SIGN_UP_CONFIRMATION));
      yield put(modalsActions.openModal(MODALS.SIGN_UP_CONFIRMATION));
      break;
    case MODALS.SIGN_UP_CONFIRMATION_FOR_VOTING:
      yield put(modalsActions.setData(DATA.SIGN_UP_CONFIRMATION_FOR_VOTING));
      yield put(modalsActions.openModal(MODALS.SIGN_UP_CONFIRMATION));
      break;
    case MODALS.SIGN_UP_CONFIRMATION_FOR_COMMENTING:
      yield put(modalsActions.setData(DATA.SIGN_UP_CONFIRMATION_FOR_COMMENTING));
      yield put(modalsActions.openModal(MODALS.SIGN_UP_CONFIRMATION));
      break;
    case MODALS.WELCOME_PROMOTION:
      yield put(modalsActions.setData({ ...DATA.WELCOME_PROMOTION, ...data }));
      yield put(modalsActions.openModal(MODALS.WELCOME_PROMOTION));
      break;
    case MODALS.WELCOME_PROMOTION_FROM_VOTING:
      yield put(modalsActions.setData({ ...DATA.WELCOME_PROMOTION_FROM_VOTING, ...data }));
      yield put(modalsActions.openModal(MODALS.WELCOME_PROMOTION));
      break;
    case MODALS.CAMPAIGN_NOT_WIN:
      yield put(modalsActions.setData(DATA.CAMPAIGN_NOT_WIN));
      yield put(modalsActions.openModal(MODALS.INFORMATION));
      break;
    case MODALS.VOTING:
      yield put(modalsActions.setData(DATA.VOTING));
      yield put(modalsActions.openModal(MODALS.VOTING));
      break;
    case MODALS.QUIZ_FINISHED:
      yield put(modalsActions.setData({ ...DATA.QUIZ_FINISHED, ...data }));
      yield put(modalsActions.openModal(MODALS.QUIZ_FINISHED));
      break;
    case MODALS.INNOVATOR_INFO_SUBMITTED:
      yield put(modalsActions.setData({ ...DATA.INNOVATOR_INFO_SUBMITTED, ...data }));
      yield put(modalsActions.openModal(MODALS.INNOVATOR_INFO_SUBMITTED));
      break;
    case MODALS.REMOVE_CREDIT_CARD:
      yield put(modalsActions.setData({ ...DATA.REMOVE_CREDIT_CARD, ...data }));
      yield put(modalsActions.openModal(MODALS.REMOVE_CREDIT_CARD));
      break;
    case MODALS.CANCEL_SUBSCRIPTION:
      yield put(modalsActions.setData({ ...DATA.CANCEL_SUBSCRIPTION, ...data }));
      yield put(modalsActions.openModal(MODALS.CANCEL_SUBSCRIPTION));
      break;
    case MODALS.IMAGE:
      yield put(modalsActions.setData(data));
      yield put(modalsActions.openModal(MODALS.IMAGE));
      break;
    case MODALS.IMAGES_SLIDER:
      yield put(modalsActions.setData(data));
      yield put(modalsActions.openModal(MODALS.IMAGES_SLIDER));
      break;
    case MODALS.IMAGE_WITH_ZOOM:
      yield put(modalsActions.setData(data));
      yield put(modalsActions.openModal(MODALS.IMAGE_WITH_ZOOM));
      break;
    case MODALS.CAMPAIGN_PASSWORD:
      yield put(modalsActions.setData(data));
      yield put(modalsActions.openModal(MODALS.CAMPAIGN_PASSWORD));
      break;
    case MODALS.REGIMEN_SENT:
      yield put(modalsActions.setData(DATA.REGIMEN_SENT));
      yield put(modalsActions.openModal(MODALS.REGIMEN_SENT));
      break;
    case MODALS.REDEEM_GIFT_CARD:
      yield put(modalsActions.setData(DATA.REDEEM_GIFT_CARD));
      yield put(modalsActions.openModal(MODALS.REDEEM_GIFT_CARD));
      break;
    case MODALS.GIFT_CARD_REDEEMED:
      yield put(modalsActions.setData(DATA.GIFT_CARD_REDEEMED));
      yield put(modalsActions.openModal(MODALS.INFORMATION));
      break;
    case MODALS.PURCHASE_GIFT_CARD:
      yield put(modalsActions.setData(data));
      yield put(modalsActions.openModal(MODALS.PURCHASE_GIFT_CARD));
      break;
    case MODALS.CHECK_OUT_THE_LOUNGE:
      yield put(modalsActions.setData({ ...DATA.CHECK_OUT_THE_LOUNGE, ...data }));
      yield put(modalsActions.openModal(MODALS.CHECK_OUT_THE_LOUNGE));
      break;
    case MODALS.JOIN_THE_LOUNGE_FOR_VOTING:
      yield put(modalsActions.setData({ ...DATA.JOIN_THE_LOUNGE_FOR_VOTING, ...data }));
      yield put(modalsActions.openModal(MODALS.JOIN_THE_LOUNGE));
      break;
    case MODALS.JOIN_THE_LOUNGE_FOR_PREORDERING:
      yield put(modalsActions.setData({ ...DATA.JOIN_THE_LOUNGE_FOR_PREORDERING, ...data }));
      yield put(modalsActions.openModal(MODALS.JOIN_THE_LOUNGE));
      break;
    case MODALS.EXTENDED_SIGN_UP:
      yield put(modalsActions.setData({ ...DATA.EXTENDED_SIGN_UP, ...data }));
      yield put(modalsActions.openModal(MODALS.EXTENDED_SIGN_UP));
      break;
    case MODALS.EXTENDED_SIGN_UP_FOR_VOTING:
      yield put(modalsActions.setData({ ...DATA.EXTENDED_SIGN_UP_FOR_VOTING, ...data }));
      yield put(modalsActions.openModal(MODALS.EXTENDED_SIGN_UP));
      break;
    default:
      break;
  }
}

export default function* modalSaga() {
  yield takeEvery(MODALS_ACTION_TYPES.CLOSE_MODAL_REQUEST, closeModal);
  yield takeEvery(MODALS_ACTION_TYPES.OPEN_MODAL_REQUEST, openModal);
}
