import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  reviewsLoadRequest: ['payload'],
  reviewsLoaded: ['payload'],
  setPage: ['payload'],
  setCurrentPage: ['payload'],
  submitReviewRequest: ['payload'],
  reviewSubmitted: ['payload'],
  reviewSubmitError: ['payload'],
}, { prefix: '[REVIEWS] ' });
