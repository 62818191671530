import { SEARCH_ACTION_TYPES } from '../../actions';

export const initialState = { searchOpened: false };

function search(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_ACTION_TYPES.OPEN_SEARCH:
      return { searchOpened: true };
    case SEARCH_ACTION_TYPES.CLOSE_SEARCH:
      return { searchOpened: false };
    default:
      return state;
  }
}

export default search;
