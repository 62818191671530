import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  enableEditMode: null,
  disableEditMode: null,
  enablePreviewMode: null,
  disablePreviewMode: null,
  enableAddressPreviewMode: null,
  disableAddressPreviewMode: null,
  selectShippingRate: ['payload'],
  startLoading: null,
  updateShippingAddressRequest: ['payload'],
  updateShippingMethodRequest: ['payload'],
  saveEmailRequest: ['payload'],
  setError: ['payload'],
}, { prefix: '[SHIPPING] ' });
