import { normalizePhone } from '../../../services/format_phone_number';
import { SHIPPING_ACTION_TYPES, ORDER_ACTION_TYPES } from '../../actions';

export const initialState = {
  editModeEnabled: true,
  previewModeEnabled: false,
  addressPreviewModeEnabled: false,
  loading: false,
  info: {},
  error: null,
  shipments: null,
};

function shipping(state = initialState, action = {}) {
  switch (action.type) {
    case SHIPPING_ACTION_TYPES.ENABLE_EDIT_MODE:
      return { ...state, editModeEnabled: true };
    case SHIPPING_ACTION_TYPES.DISABLE_EDIT_MODE:
      return { ...state, editModeEnabled: false };
    case SHIPPING_ACTION_TYPES.ENABLE_PREVIEW_MODE:
      return { ...state, previewModeEnabled: true };
    case SHIPPING_ACTION_TYPES.DISABLE_PREVIEW_MODE:
      return { ...state, previewModeEnabled: false };
    case SHIPPING_ACTION_TYPES.ENABLE_ADDRESS_PREVIEW_MODE:
      return { ...state, addressPreviewModeEnabled: true };
    case SHIPPING_ACTION_TYPES.DISABLE_ADDRESS_PREVIEW_MODE:
      return { ...state, addressPreviewModeEnabled: false };
    case SHIPPING_ACTION_TYPES.SELECT_SHIPPING_RATE:
      return {
        ...state,
        shipments: state.shipments.map((shipment) => {
          if (shipment.id !== action.payload.shipmentId) {
            return shipment;
          }

          return (
            {
              ...shipment,
              shipping_rates: shipment.shipping_rates.map(
                (rate) => ({ ...rate, selected: rate.id === action.payload.shippingRateId }),
              ),
            });
        }),
      };
    case SHIPPING_ACTION_TYPES.UPDATE_SHIPPING_ADDRESS_REQUEST:
    case SHIPPING_ACTION_TYPES.UPDATE_SHIPPING_METHOD_REQUEST:
      return { ...state, loading: true };
    case SHIPPING_ACTION_TYPES.SET_ERROR:
      return { ...state, error: action.payload };
    case ORDER_ACTION_TYPES.ORDER_LOADED: {
      const order = action.payload;
      const shipAddress = order.ship_address;
      const { shipments } = order;
      const { loading } = initialState;
      let {
        editModeEnabled, previewModeEnabled, info, addressPreviewModeEnabled,
      } = initialState;

      if (order.state === 'payment' || order.state === 'confirm') {
        previewModeEnabled = true;
      }
      if (order.state === 'delivery' || order.state === 'payment' || order.state === 'confirm') {
        addressPreviewModeEnabled = true;
      }

      if (shipAddress) {
        info = {
          email: order.email,
          opt_in_phone: order.opt_in_phone,
          phone: normalizePhone(shipAddress.phone),
          country_id: shipAddress.country_id || order.available_countries[0]?.id,
          state_id: shipAddress.state_id || order.states[0]?.id,
          firstname: shipAddress.firstname,
          lastname: shipAddress.lastname,
          address1: shipAddress.address1,
          address2: shipAddress.address2,
          city: shipAddress.city,
          zipcode: shipAddress.zipcode,
        };
        editModeEnabled = !shipAddress.city || !shipAddress.state_id || !shipAddress.zipcode;
      } else {
        info = {
          email: order.email,
          opt_in_phone: order.opt_in_phone,
          country_id: order.available_countries[0]?.id,
          state_id: order.states[0]?.id,
        };
      }

      return {
        ...state,
        info,
        editModeEnabled,
        previewModeEnabled,
        addressPreviewModeEnabled,
        loading,
        shipments,
      };
    }
    default:
      return state;
  }
}

export default shipping;
