import { takeEvery, call, put } from 'redux-saga/effects';
import api from '../../api';
import { LINE_ITEMS_ACTION_TYPES, lineItemsActions, orderActions } from '../../actions';
import { URLS } from '../../constants';

export function* updateLineItemsQuantity(action) {
  function* onRequestComplete(response) {
    if (window.location.pathname.includes('/shop/checkout_now')) {
      if (response.line_items.length === 0) {
        window.location.assign(URLS.SHOP_PATH);
        return;
      }

      if (!response.user && response.line_items.filter(
        (lineItem) => lineItem.frequency > 0,
      ).length !== 0) {
        window.location.assign(`${URLS.SHOP_PATH}?show_sign_in_for_product_subscription=true`);
        return;
      }
    }

    if (Object.keys(response.errors).length > 0) {
      const [[error]] = Object.values(response.errors);
      yield put(lineItemsActions.setError(error));
    }

    yield put(orderActions.orderLoaded(response));
    yield put(lineItemsActions.stopLoading({ id: action.payload.id }));
  }

  yield put(lineItemsActions.startLoading({ id: action.payload.id }));

  try {
    const response = yield call(api.updateLineItemsQuantity, action.payload);
    yield* onRequestComplete(response.data);
  } catch (error) {
    yield put(lineItemsActions.stopLoading({ id: action.payload.id }));
  }
}

export default function* lineItemsSaga() {
  yield takeEvery(LINE_ITEMS_ACTION_TYPES.UPDATE_QUANTITY_REQUEST, updateLineItemsQuantity);
}
