const URLS = {
  LOGIN_URL: '/users/sign_in.json',
  LOGOUT_URL: '/users/sign_out.json',
  REGISTER_URL: '/users.json',
  RESET_PASSWORD_URL: '/users/password.json',
  RESEND_CONFIRMATION_EMAIL_URL: '/users/confirmation.json',
  FACEBOOK_AUTH_CALLBACK_URL: '/users/auth/facebook/callback',
  GOOGLE_AUTH_CALLBACK_URL: '/users/auth/google_oauth2/callback',
  WELCOME_PROMOTION_URL: '/users/welcome_promotion.json',
  GIFT_CARD_PRODUCTS_URL: '/shop/gift_cards.json',
  ADD_GIFT_CARD_PRODUCT_URL: '/shop/add_gift_card.json',
};

export default URLS;
