import axios from '../../services/axios';

function loadBestSellers() {
  return axios({
    method: 'GET',
    url: '/best_sellers.json',
  });
}

function loadInnovatorHighlights() {
  return axios({
    method: 'GET',
    url: '/innovator_highlights.json?show_on_home_page=true',
  });
}

const api = {
  loadBestSellers,
  loadInnovatorHighlights,
};

export default api;
