import { takeEvery, call, put } from 'redux-saga/effects';
import api from '../../api';
import { reviewsActions, REVIEWS_ACTION_TYPES } from '../../actions';

export function* loadReviews(action) {
  const {
    sourceId, page, perPage, isCampaign,
  } = action.payload;
  let loadReviewsOrComments = api.loadReviews;
  if (isCampaign) {
    loadReviewsOrComments = api.loadCampaignComments;
  }
  const response = yield call(loadReviewsOrComments, sourceId, { per_page: perPage, page });
  yield put(reviewsActions.reviewsLoaded(response.data));
  yield put(reviewsActions.setPage(page + 1));
}

export function* submitReview(action) {
  const { sourceId, params, isCampaign } = action.payload;
  let submitReviewOrComment = api.submitReview;
  if (isCampaign) {
    submitReviewOrComment = api.submitCampaignComment;
    params.text = params.content;
  }
  try {
    const response = yield call(submitReviewOrComment, sourceId, params);
    yield put(reviewsActions.reviewSubmitted(response.data));
  } catch (error) {
    yield put(reviewsActions.reviewSubmitError(error.response.data.error));
  }
}

export default function* reviewsSaga() {
  yield takeEvery(REVIEWS_ACTION_TYPES.REVIEWS_LOAD_REQUEST, loadReviews);
  yield takeEvery(REVIEWS_ACTION_TYPES.SUBMIT_REVIEW_REQUEST, submitReview);
}
