import { takeEvery, call, put } from 'redux-saga/effects';
import api from '../../api';
import { bestSellersActions, BEST_SELLERS_ACTION_TYPES } from '../../actions';

export function* loadBestSellers() {
  const response = yield call(api.loadBestSellers);
  const products = response.data;
  yield put(bestSellersActions.setBestSellers(products));
}

export default function* bestSellersSaga() {
  yield takeEvery(BEST_SELLERS_ACTION_TYPES.LOAD_BEST_SELLERS_REQUEST, loadBestSellers);
}
