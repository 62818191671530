import { LINE_ITEMS_ACTION_TYPES, ORDER_ACTION_TYPES } from '../../actions';
import lineItemReducer from '../line_item';

export const initialState = {
  error: null,
  objects: [],
};

function lineItems(state = initialState, action = {}) {
  function findLineItemById(id) {
    return state.objects.find((lineItem) => lineItem.id === id);
  }

  switch (action.type) {
    case LINE_ITEMS_ACTION_TYPES.SET_ERROR:
      return { ...state, error: action.payload };
    case LINE_ITEMS_ACTION_TYPES.REMOVE_ERROR:
      return { ...state, error: null };
    case LINE_ITEMS_ACTION_TYPES.START_LOADING:
    case LINE_ITEMS_ACTION_TYPES.STOP_LOADING:
      return {
        ...state,
        objects: state.objects.map((lineItem) => lineItemReducer(lineItem, action)),
      };
    case ORDER_ACTION_TYPES.ORDER_LOADED:
      return {
        ...state,
        objects: action.payload.line_items.map((lineItem) => (
          {
            ...lineItem,
            loading: !!(findLineItemById(lineItem.id) && findLineItemById(lineItem.id).loading),
          }
        )),
      };
    default:
      return state;
  }
}

export default lineItems;
