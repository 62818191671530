import { takeEvery, call, put } from 'redux-saga/effects';
import { stopSubmit, touch } from 'redux-form';
import api from '../../api';
import { userActions, USER_ACTION_TYPES } from '../../actions';
import { modalsActions } from '../../../modals/actions';

export function* updateDescription(action) {
  const { userId, description } = action.payload;
  yield put(userActions.setDescriptionLoading(true));
  const response = yield call(api.updateUser, userId, { user: { description } });
  yield put(userActions.setDescriptionLoading(false));
  yield put(userActions.setDescriptionEditMode(false));
  yield put(userActions.setUser(response.data));
}

export function* updateImage(action) {
  const { userId, file } = action.payload;
  yield put(userActions.setImageLoading(true));
  const response = yield call(api.updateImage, userId, file);
  yield put(userActions.setImageLoading(false));
  yield put(userActions.setUser(response.data));
}

export function* updateAccountInformation(action) {
  const { userId, params } = action.payload;
  yield put(userActions.setAccountInformationLoading(true));
  const response = yield call(api.updateUser, userId, { user: { ...params } });
  yield put(userActions.setUser(response.data));
  yield put(userActions.setAccountInformationLoading(false));
  yield put(userActions.setAccountInformationEditMode(false));
}

export function* updateAddress(action) {
  const { userId, params } = action.payload;
  yield put(userActions.setAddressBookLoading(true));
  const response = yield call(api.updateUser, userId, { user: { ...params } });
  yield put(userActions.setUser(response.data));
  yield put(userActions.setAddressBookLoading(false));
  yield put(userActions.setAddressBookEditMode(false));
}

export function* removeCreditCard(action) {
  const { userId, cardId } = action.payload;
  yield put(userActions.removeCreditCard({ id: cardId }));
  yield put(modalsActions.closeModal());
  const response = yield call(api.removeCreditCard, userId, cardId);
  yield put(userActions.setUser(response.data));
}

export function* updateNewsletterSubscription(action) {
  const { userId, params } = action.payload;
  yield put(userActions.setNewsletterSubscriptionLoading(true));
  const response = yield call(api.updateUser, userId, { user: { ...params } });
  yield put(userActions.setUser(response.data));
  yield put(userActions.setNewsletterSubscriptionLoading(false));
  yield put(userActions.setNewsletterSubscriptionEditMode(false));
}

export function* updatePassword(action) {
  const { userId, params } = action.payload;
  yield put(userActions.setPasswordLoading(true));
  try {
    const response = yield call(api.updatePassword, userId, { user: { ...params } });
    yield put(userActions.setUser(response.data));
    yield put(userActions.setPasswordLoading(false));
    yield put(userActions.setPasswordEditMode(false));
  } catch (error) {
    const { data } = error.response;
    const errors = {};
    if (data.current_password) {
      errors.current_password = `Current password ${data.current_password[0]}`;
    }
    if (data.password) {
      errors.password = `New password ${data.password[0]}`;
    }
    yield put(stopSubmit('password', errors));
    yield put(touch('password', Object.keys(errors)));
    yield put(userActions.setPasswordLoading(false));
  }
}

export default function* userSaga() {
  yield takeEvery(USER_ACTION_TYPES.UPDATE_DESCRIPTION_REQUEST, updateDescription);
  yield takeEvery(USER_ACTION_TYPES.UPDATE_IMAGE_REQUEST, updateImage);
  yield takeEvery(USER_ACTION_TYPES.UPDATE_ACCOUNT_INFORMATION_REQUEST, updateAccountInformation);
  yield takeEvery(USER_ACTION_TYPES.UPDATE_ADDRESS_REQUEST, updateAddress);
  yield takeEvery(USER_ACTION_TYPES.CREDIT_CARD_REMOVE_REQUEST, removeCreditCard);
  yield takeEvery(
    USER_ACTION_TYPES.UPDATE_NEWSLETTER_SUBSCRIPTION_REQUEST,
    updateNewsletterSubscription,
  );
  yield takeEvery(USER_ACTION_TYPES.UPDATE_PASSWORD_REQUEST, updatePassword);
}
