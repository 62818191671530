import { takeEvery, call, put } from 'redux-saga/effects';
import api from '../../api';
import { CAMPAIGNS_ACTION_TYPES, campaignsActions } from '../../actions';
import { modalsActions } from '../../../modals/actions';
import MODALS from '../../../modals/constants/modals';

export function getFingerprint() {
  return new Promise((resolve) => {
    import('fingerprintjs2').then((Fingerprint2) => {
      Fingerprint2.getV18(resolve);
    });
  });
}

export function* vote(action) {
  const {
    campaignId, variantId, user, showDefaultVoteDialog,
  } = action.payload;

  yield put(campaignsActions.startLoadingCampaign({ id: campaignId }));

  // check that user haven't voted for this campaign before
  let response = yield call(api.getVotes);
  const ids = response.data;
  if (ids.includes(campaignId)) {
    yield put(campaignsActions.stopLoadingCampaign({ id: campaignId }));
    yield put(campaignsActions.vote({ id: campaignId }));
    return;
  }

  // get fingerprint and vote for the campaign
  const fingerprint = yield call(getFingerprint);
  response = yield call(api.vote, campaignId, variantId, fingerprint);
  if (response.data.confirmed) {
    // increase votes count only if vote is confirmed
    yield put(campaignsActions.increaseVotesCount({ id: campaignId }));
  }
  yield put(campaignsActions.stopLoadingCampaign({ id: campaignId }));
  yield put(campaignsActions.vote({ id: campaignId }));

  // we should not show vote dialog in some cases (e.g. signing up with provider)
  if (!showDefaultVoteDialog) { return; }

  if (user.confirmed_at) {
    yield put(modalsActions.openModalRequest({
      modal: MODALS.VOTING,
    }));
  } else {
    yield put(modalsActions.openModalRequest({
      modal: MODALS.RESEND_SIGN_UP_CONFIRMATION_FOR_VOTING,
    }));
  }
}

export function* loadCampaigns() {
  const response = yield call(api.loadCampaigns);
  yield put(campaignsActions.setCampaigns(response.data));
}

export function* upvote(action) {
  const { campaignId } = action.payload;

  yield put(campaignsActions.upvote({ id: campaignId }));
  const fingerprint = yield call(getFingerprint);
  yield call(api.upvote, campaignId, fingerprint);
}

export function* downvote(action) {
  const { campaignId } = action.payload;

  yield put(campaignsActions.downvote({ id: campaignId }));
  const fingerprint = yield call(getFingerprint);
  yield call(api.downvote, campaignId, fingerprint);
}

export default function* campaignsSaga() {
  yield takeEvery(CAMPAIGNS_ACTION_TYPES.VOTE_REQUEST, vote);
  yield takeEvery(CAMPAIGNS_ACTION_TYPES.LOAD_CAMPAIGNS_REQUEST, loadCampaigns);
  yield takeEvery(CAMPAIGNS_ACTION_TYPES.UPVOTE_REQUEST, upvote);
  yield takeEvery(CAMPAIGNS_ACTION_TYPES.DOWNVOTE_REQUEST, downvote);
}
