export default {
  ALGOLIA_APPLICATION_ID: '74P3FG4DIP',
  ALGOLIA_SEARCH_API_KEY: '16e1e7ffa8eb4c6092eab84cd3ebcaa0',
  ALGOLIA_CAMPAIGNS_INDEX: 'Campaign_production',
  ALGOLIA_PRODUCTS_INDEX: 'Spree_Product_production',
  ALGOLIA_PAGES_INDEX: 'SearchablePage_production',
  FACEBOOK_PRODUCT_CATALOG_ID: '1727033207566823',
  ADDRESS_FINDER: {
    PCA_PREDICT_API_KEY: 'JP46-GB54-DK79-BP61',
  },
  AMAZON: {
    SELLER_ID: 'A2XUHOUK38E4MO',
  },
  ENVIRONMENT: 'production',
  SERVICE_EMAIL: "service@volitionbeauty.com",
};
