import { PRODUCTS_ACTION_TYPES } from '../../actions';

export const initialState = {};

function product(state = initialState, action = {}) {
  switch (action.type) {
    case PRODUCTS_ACTION_TYPES.START_LOADING_PRODUCT:
      if (action.payload.id !== state.id) { return state; }
      return { ...state, loading: true };
    case PRODUCTS_ACTION_TYPES.STOP_LOADING_PRODUCT:
      if (action.payload.id !== state.id) { return state; }
      return { ...state, loading: false };
    case PRODUCTS_ACTION_TYPES.PRODUCT_SUBSCRIBED:
      if (action.payload.id !== state.id) { return state; }
      return { ...state, subscribed: true };
    default:
      return state;
  }
}

export default product;
