import axios from '../../services/axios';

function updateUser(userId, params) {
  return axios({
    method: 'PUT',
    url: `/users/${userId}`,
    params,
  });
}

function updatePassword(userId, params) {
  return axios({
    method: 'PATCH',
    url: `/users/${userId}/update_password`,
    params,
  });
}

function updateImage(userId, file) {
  const formData = new FormData();
  formData.append('user[photo]', file);

  return axios({
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'PUT',
    url: `/users/${userId}`,
    data: formData,
  });
}

function removeCreditCard(userId, cardId) {
  return axios({
    method: 'DELETE',
    url: `/users/${userId}/credit_cards/${cardId}/`,
  });
}

const api = {
  updateUser,
  updateImage,
  updatePassword,
  removeCreditCard,
};

export default api;
