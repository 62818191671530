import { PAYMENT_ACTION_TYPES, ORDER_ACTION_TYPES } from '../../actions';

export const initialState = {
  stripeToken: null,
  selectedPaymentMethod: null,
  selectedCreditCard: null,
  loading: false,
  storeCreditLoading: false,
  error: null,
};

function payment(state = initialState, action = {}) {
  switch (action.type) {
    case PAYMENT_ACTION_TYPES.SELECT_PAYMENT_METHOD:
      return { ...state, selectedPaymentMethod: action.payload };
    case PAYMENT_ACTION_TYPES.SELECT_CREDIT_CARD:
      return { ...state, selectedCreditCard: action.payload };
    case PAYMENT_ACTION_TYPES.SET_STRIPE_TOKEN:
      return { ...state, stripeToken: action.payload };
    case PAYMENT_ACTION_TYPES.REMOVE_STRIPE_TOKEN:
      return { ...state, stripeToken: null };
    case PAYMENT_ACTION_TYPES.START_LOADING:
      return { ...state, loading: true };
    case PAYMENT_ACTION_TYPES.STOP_LOADING:
      return { ...state, loading: false };
    case PAYMENT_ACTION_TYPES.START_LOADING_STORE_CREDIT:
      return { ...state, storeCreditLoading: true };
    case PAYMENT_ACTION_TYPES.STOP_LOADING_STORE_CREDIT:
      return { ...state, storeCreditLoading: false };
    case PAYMENT_ACTION_TYPES.SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };
    case PAYMENT_ACTION_TYPES.REMOVE_ERROR_MESSAGE:
      return { ...state, error: null };
    case ORDER_ACTION_TYPES.ORDER_LOADED:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default payment;
