import { REVIEWS_ACTION_TYPES } from '../../actions';

export const initialState = {
  loading: true,
  page: 1,
  perPage: 6,
  currentPage: 1,
  entries: [],
  reviewSubmitting: false,
  reviewSubmittingError: false,
  reviewSubmitted: false,
  total_pages: 0,
};

function reviews(state = initialState, action = {}) {
  switch (action.type) {
    case REVIEWS_ACTION_TYPES.REVIEWS_LOAD_REQUEST:
      return { ...state, loading: true };
    case REVIEWS_ACTION_TYPES.REVIEWS_LOADED:
      return {
        ...state,
        entries: [...state.entries, ...action.payload.entries],
        total: action.payload.total,
        rating: action.payload.rating,
        total_pages: action.payload.total_pages,
        loading: false,
      };
    case REVIEWS_ACTION_TYPES.SET_PAGE:
      return { ...state, page: action.payload };
    case REVIEWS_ACTION_TYPES.SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case REVIEWS_ACTION_TYPES.SUBMIT_REVIEW_REQUEST:
      return { ...state, reviewSubmitting: true };
    case REVIEWS_ACTION_TYPES.REVIEW_SUBMITTED:
      return {
        ...state,
        reviewSubmitting: false,
        reviewSubmitted: true,
        entries: [action.payload, ...state.entries],
      };
    case REVIEWS_ACTION_TYPES.REVIEW_SUBMIT_ERROR:
      return { ...state, reviewSubmittingError: true, reviewSubmitting: false };
    default:
      return state;
  }
}

export default reviews;
