import MAP_STYLES from '../constants/map_styles';
import MAP_CONFIG from '../constants/map_config';
import Icon from '../../../images/icons/map-mark.svg';
import PinA from '../../../images/icons/pin-a.svg';
import PinB from '../../../images/icons/pin-b.svg';
import buildInfoWindowContent from './infoWindow';

const ZOOM = {
  STORES_FOUND: 7,
  STORES_NOT_FOUND: 3,
  DIRECTION_MODE: 15,
};

function defaultLocation() {
  const { google } = window;

  const USA_GEO_CENTER = { lat: 39.8283459, lng: -98.5794797 };
  return {
    coords: new google.maps.LatLng(USA_GEO_CENTER.lat, USA_GEO_CENTER.lng),
    zoom: ZOOM.STORES_NOT_FOUND,
  };
}

class MapService {
  constructor(id, shops) {
    this.id = id;
    this.shops = shops;
  }

  init() {
    const { google } = window;
    this.map = new google.maps.Map(document.getElementById(this.id), {
      zoom: MAP_CONFIG.ZOOM,
      center: MAP_CONFIG.CENTER,
      styles: MAP_STYLES,
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: true,
    });
    window.map = this.map;
    window.mapMarkers = [];
    window.openedInfoWindows = [];

    this.addMarkers();
  }

  addMarkers() {
    const { google } = window;

    this.shops.forEach((shop) => {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(shop.latitude, shop.longitude),
        icon: Icon,
        map: this.map,
        id: shop.id,
      });
      const infoWindow = new google.maps.InfoWindow({
        content: buildInfoWindowContent(shop),
        marker,
      });
      window.mapMarkers.push(marker);
      marker.addListener('click', () => {
        window.openedInfoWindows.forEach((openedInfoWindows) => {
          openedInfoWindows.close();
        });
        infoWindow.open(this.map, marker);
        window.openedInfoWindows.push(infoWindow);
        window.infoWindowOpened(shop.id);
      });
      infoWindow.addListener('closeclick', () => {
        window.infoWindowClosed(shop.id);
      });
    });
  }

  enableDirectionMode() {
    const {
      mapMarkers, directionTo, google,
    } = window;

    mapMarkers.forEach((marker) => {
      if (marker.id !== directionTo.id) { marker.setMap(null); }
    });
    const mapCenter = {
      coords: new google.maps.LatLng(directionTo.latitude, directionTo.longitude),
      zoom: ZOOM.DIRECTION_MODE,
    };

    this.map.panTo(mapCenter.coords);
    this.map.setZoom(mapCenter.zoom);
  }

  disableDirectionMode() {
    if (window.directionsRenderer) { window.directionsRenderer.setMap(null); }

    const {
      map, directionMarkers,
    } = window;
    if (directionMarkers) { window.directionMarkers.forEach((marker) => marker.setMap(null)); }

    window.mapMarkers.forEach((marker) => (marker.setMap(map)));

    const mapCenter = defaultLocation();
    this.map.panTo(mapCenter.coords);
    this.map.setZoom(mapCenter.zoom);
  }

  buildRoute(origin, travelMode = 'DRIVING') {
    const { google, directionTo, mapMarkers } = window;
    const destination = new google.maps.LatLng(directionTo.latitude, directionTo.longitude);
    if (!origin || origin.length === 0) {
      mapMarkers.forEach((marker) => {
        if (marker.id === directionTo.id) { marker.setMap(this.map); }
      });
      this.map.panTo(destination);
      return;
    }
    if (window.directionsRenderer) { window.directionsRenderer.setMap(null); }
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({ suppressMarkers: true });
    directionsRenderer.setMap(this.map);

    window.directionsRenderer = directionsRenderer;
    directionsService.route(
      { origin, destination, travelMode },
      (response, status) => {
        if (status === 'OK') {
          if (!window.directionMarkers) {
            window.directionMarkers = [];
          }
          window.directionsRenderer.setDirections(response);
          const route = response.routes[0].legs[0];
          const pinA = new google.maps.Marker({
            position: route.start_location,
            map: this.map,
            icon: PinA,
          });
          const pinB = new google.maps.Marker({
            position: route.end_location,
            map: this.map,
            icon: PinB,
          });
          const infoWindow = new google.maps.InfoWindow({
            content: buildInfoWindowContent(directionTo),
          });
          pinB.addListener('click', () => { infoWindow.open(this.map, pinB); });
          window.directionMarkers.forEach((marker) => marker.setMap(null));
          mapMarkers.forEach((marker) => {
            if (marker.id === directionTo.id) { marker.setMap(null); }
          });
          window.directionMarkers.push(pinA, pinB);
        }
      },
    );
  }
}

function reinitMapMarkers(collection, panToCenter = false) {
  const { map, mapMarkers, google } = window;
  const mapCenter = defaultLocation();

  const [theMostNearShop] = collection;
  if (theMostNearShop && !panToCenter) {
    mapCenter.coords = new google.maps.LatLng(theMostNearShop.latitude, theMostNearShop.longitude);
    mapCenter.zoom = ZOOM.STORES_FOUND;
  }

  if (mapMarkers.length === 0) { return; }

  mapMarkers.forEach((marker) => (marker.setMap(null)));

  const { mapService } = window;
  mapService.map = map;
  mapService.shops = collection;
  mapService.addMarkers();
  map.panTo(mapCenter.coords);
  map.setZoom(mapCenter.zoom);
}

const mapServices = {
  MapService,
  reinitMapMarkers,
};

export default mapServices;
