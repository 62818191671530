import axios from '../../services/axios';

function loadReviews(productId, params) {
  return axios({
    method: 'GET',
    url: `/shop/products/${productId}/reviews.json`,
    params,
  });
}

function loadCampaignComments(campaignId, params) {
  return axios({
    method: 'GET',
    url: `/campaigns/${campaignId}/comments.json`,
    params,
  });
}

function submitReview(productId, reviewParams) {
  const params = reviewParams;
  const data = new FormData();
  const { images } = params;
  images.forEach((image) => {
    data.append('review[pictures_attributes][][source]', image, image.name);
  });
  delete params.images;
  Object.entries(params).forEach(([key, value]) => {
    data.append(`review[${key}]`, value);
  });

  return axios({
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    url: `/shop/products/${productId}/reviews.json`,
    data,
  });
}

function submitCampaignComment(campaignId, params) {
  return axios({
    method: 'POST',
    url: `/campaigns/${campaignId}/comments.json`,
    params: {
      comment: params,
    },
  });
}

const api = {
  loadReviews,
  submitReview,
  loadCampaignComments,
  submitCampaignComment,
};

export default api;
