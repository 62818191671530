import { PRODUCTS_ACTION_TYPES } from '../../actions';
import productReducer from '../product';

export const initialState = {
  objects: [],
  loading: false,
  page: 1,
  hasMore: true,
  crossSells: [],
};

function products(state = initialState, action = {}) {
  switch (action.type) {
    case PRODUCTS_ACTION_TYPES.START_LOADING_PRODUCT:
    case PRODUCTS_ACTION_TYPES.STOP_LOADING_PRODUCT:
    case PRODUCTS_ACTION_TYPES.PRODUCT_SUBSCRIBED:
      return { ...state, objects: state.objects.map((product) => productReducer(product, action)) };
    case PRODUCTS_ACTION_TYPES.START_LOADING:
      return { ...state, loading: true };
    case PRODUCTS_ACTION_TYPES.STOP_LOADING:
      return { ...state, loading: false };
    case PRODUCTS_ACTION_TYPES.ADD_PRODUCTS:
      return { ...state, objects: Array.from(new Set([...state.objects, ...action.payload])) };
    case PRODUCTS_ACTION_TYPES.ADD_CROSS_SELLS:
      return { ...state, crossSells: [...state.crossSells, ...action.payload] };
    case PRODUCTS_ACTION_TYPES.SET_CURRENT_PAGE:
      return { ...state, page: action.payload };
    case PRODUCTS_ACTION_TYPES.SET_HAS_MORE:
      return { ...state, hasMore: action.payload };
    default:
      return state;
  }
}

export default products;
