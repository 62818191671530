import COMMON_CONSTANTS from '../common/constants.js.erb';

function trackAddToBag(productId, price) {
  if (!window.fbq) { return; }
  window.fbq('track', 'AddToCart', {
    content_ids: productId,
    content_type: 'product',
    product_catalog_id: COMMON_CONSTANTS.FACEBOOK_PRODUCT_CATALOG_ID,
    value: price,
    currency: 'USD',
  });
}

function multiPurposeTrack(event) {
  if (!window.fbq) { return; }
  window.fbq('track', event);
}

const facebookPixel = {
  trackAddToBag,
  multiPurposeTrack,
};

export default facebookPixel;
