import axios from '../../services/axios';
import { URLS } from '../constants';

function selectAmazonPayment(params) {
  return axios({
    method: 'POST',
    url: URLS.AMAZON_PAYMENT_SELECT_PATH,
    params,
  });
}

function confirmAmazonPayment() {
  return axios({
    method: 'POST',
    url: URLS.AMAZON_PAYMENT_CONFIRM_PATH,
  });
}

function updateLineItemsQuantity(params) {
  return axios({
    method: 'PATCH',
    url: URLS.UPDATE_CART_PATH,
    params,
  });
}

function loadOrder() {
  return axios({
    method: 'GET',
    url: URLS.ORDER_PATH,
  });
}

function updatePayment(params) {
  return axios({
    method: 'PUT',
    url: URLS.UPDATE_PAYMENT_PATH,
    params,
  });
}

function updateShippingAddress(params) {
  return axios({
    method: 'PUT',
    url: URLS.UPDATE_SHIPPING_ADDRESS_PATH,
    params,
  });
}

function updateShippingMethod(params) {
  return axios({
    method: 'PUT',
    url: URLS.UPDATE_SHIPPING_METHOD_PATH,
    params,
  });
}

function saveEmail(params) {
  return axios({
    method: 'PATCH',
    url: URLS.API_PATH.concat(params.id).concat(URLS.EMAIL_UPDATE_PATH),
    params,
  });
}

function addFreeSample(params) {
  return axios({
    method: 'POST',
    url: URLS.ADD_FREE_SAMPLE_PATH,
    params,
  });
}

function removeFreeSample(params) {
  return axios({
    method: 'POST',
    url: URLS.REMOVE_FREE_SAMPLE_PATH,
    params,
  });
}

function applyStoreCredit(params) {
  return axios({
    method: 'PUT',
    url: URLS.APPLY_STORE_CREDIT_PATH,
    params,
  });
}

function removeStoreCredit(params) {
  return axios({
    method: 'PUT',
    url: URLS.REMOVE_STORE_CREDIT_PATH,
    params,
  });
}

const api = {
  confirmAmazonPayment,
  loadOrder,
  selectAmazonPayment,
  updateLineItemsQuantity,
  updatePayment,
  updateShippingAddress,
  updateShippingMethod,
  saveEmail,
  addFreeSample,
  removeFreeSample,
  applyStoreCredit,
  removeStoreCredit,
};

export default api;
