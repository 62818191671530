import { CAMPAIGNS_ACTION_TYPES } from '../../actions';
import campaignReducer from '../campaign';

export const DEFAULT_WAITLIST_CAMPAIGNS_COUNT = 6;
export const DEFAULT_PREORDER_CAMPAIGNS_COUNT = 6;

export const initialState = {
  objects: [],
  categories: [],
  selectedCategoryId: null,
  visibleWaitlistCampaignsCount: DEFAULT_WAITLIST_CAMPAIGNS_COUNT,
  visiblePreorderCampaignsCount: DEFAULT_PREORDER_CAMPAIGNS_COUNT,
};

function campaigns(state = initialState, action = {}) {
  switch (action.type) {
    case CAMPAIGNS_ACTION_TYPES.START_LOADING_CAMPAIGN:
    case CAMPAIGNS_ACTION_TYPES.STOP_LOADING_CAMPAIGN:
    case CAMPAIGNS_ACTION_TYPES.VOTE:
    case CAMPAIGNS_ACTION_TYPES.INCREASE_VOTES_COUNT:
    case CAMPAIGNS_ACTION_TYPES.SELECT_VARIANT_ID:
    case CAMPAIGNS_ACTION_TYPES.UPVOTE:
    case CAMPAIGNS_ACTION_TYPES.DOWNVOTE:
      return {
        ...state,
        objects: state.objects.map((campaign) => campaignReducer(campaign, action)),
      };
    case CAMPAIGNS_ACTION_TYPES.SET_CAMPAIGNS:
      return {
        ...state,
        objects: action.payload.map((campaign) => {
          const campaignFromState = state.objects.find((object) => campaign.id === object.id);
          return {
            ...campaign,
            loading: campaignFromState ? campaignFromState.loading : false,
            selectedVariantId: campaignFromState ? campaignFromState.selectedVariantId : null,
          };
        }),
      };
    case CAMPAIGNS_ACTION_TYPES.SET_CAMPAIGNS_BY_CATEGORY:
      return { ...state, campaignsByCategory: action.payload };
    case CAMPAIGNS_ACTION_TYPES.SET_CATEGORIES:
      return { ...state, categories: action.payload };
    case CAMPAIGNS_ACTION_TYPES.SET_SELECTED_CATEGORY_ID:
      return { ...state, selectedCategoryId: action.payload };
    default:
      return state;
  }
}

export default campaigns;
