import { CAMPAIGNS_ACTION_TYPES } from '../../actions';

export const initialState = {};

function campaign(state = initialState, action = {}) {
  switch (action.type) {
    case CAMPAIGNS_ACTION_TYPES.START_LOADING_CAMPAIGN:
      if (action.payload.id !== state.id) { return state; }
      return { ...state, loading: true };
    case CAMPAIGNS_ACTION_TYPES.STOP_LOADING_CAMPAIGN:
      if (action.payload.id !== state.id) { return state; }
      return { ...state, loading: false };
    case CAMPAIGNS_ACTION_TYPES.VOTE:
      if (action.payload.id !== state.id) { return state; }
      return { ...state, is_voted: true };
    case CAMPAIGNS_ACTION_TYPES.INCREASE_VOTES_COUNT:
      if (action.payload.id !== state.id) { return state; }
      return { ...state, votes_count: state.votes_count + 1 };
    case CAMPAIGNS_ACTION_TYPES.SELECT_VARIANT_ID:
      if (action.payload.id !== state.id) { return state; }
      return { ...state, selectedVariantId: action.payload.selectedVariantId };
    case CAMPAIGNS_ACTION_TYPES.UPVOTE:
      if (action.payload.id !== state.id) { return state; }
      return {
        ...state,
        upvotes_total: state.upvotes_total + (state.is_downvoted ? 2 : 1),
        is_downvoted: false,
        is_upvoted: true,
      };
    case CAMPAIGNS_ACTION_TYPES.DOWNVOTE:
      if (action.payload.id !== state.id) { return state; }
      return {
        ...state,
        upvotes_total: state.upvotes_total - (state.is_upvoted ? 2 : 1),
        is_downvoted: true,
        is_upvoted: false,
      };
    default:
      return state;
  }
}

export default campaign;
