import axios from '../../services/axios';

function loadShops(params) {
  return axios({
    method: 'GET',
    url: '/shops/index.json',
    params: {
      search: params,
    },
  });
}

function getAddress(params) {
  return axios({
    method: 'GET',
    url: '/shops/address.json',
    params: {
      coordinates: params,
    },
  });
}

const api = {
  loadShops,
  getAddress,
};

export default api;
