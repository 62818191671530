import { TAXONS_ACTION_TYPES } from '../../actions';

export const initialState = [];

function taxons(state = initialState, action = {}) {
  switch (action.type) {
    case TAXONS_ACTION_TYPES.TAXONS_LOADED:
      return action.payload;
    default:
      return state;
  }
}

export default taxons;
