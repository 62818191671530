import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  shopsLoadRequest: ['payload'],
  shopsLoaded: ['payload'],
  setPage: ['payload'],
  setShowOnMap: ['payload'],
  setShops: ['payload'],
  setUserAddress: ['payload'],
  setSearchQuery: ['payload'],
  setSelectedState: ['payload'],
  assignDefaultData: ['payload'],
  setDirectionMode: ['payload'],
  setDirectionTo: ['payload'],
  setTravelMode: ['payload'],
  setActiveStoreId: ['payload'],
  toggleViewAllShops: [],
  incrementPage: [],
}, { prefix: '[SHOPS] ' });
