import { ORDER_ACTION_TYPES } from '../../../checkout/actions';
import { PRODUCTS_ACTION_TYPES } from '../../../products/actions';
import upSellReducer from './up_sell';

export const initialState = [];

function upSells(state = initialState, action = {}) {
  function findUpSellById(id) {
    return state.find((upSell) => upSell.id === id);
  }

  switch (action.type) {
    case ORDER_ACTION_TYPES.ORDER_LOADED:
      return action.payload.up_sells.map((upSell) => (
        {
          ...upSell,
          loading: !!(findUpSellById(upSell.id) && findUpSellById(upSell.id).loading),
        }
      ));
    case PRODUCTS_ACTION_TYPES.START_LOADING_PRODUCT:
    case PRODUCTS_ACTION_TYPES.STOP_LOADING_PRODUCT:
      return state.map((product) => upSellReducer(product, action));
    default:
      return state;
  }
}

export default upSells;
