import axios from '../../services/axios';

function applyCouponCode(orderNumber, code, guestToken) {
  const params = {
    coupon_code: code,
    order_token: guestToken,
  };

  return axios({
    method: 'PUT',
    url: `/shop/api/v1/orders/${orderNumber}/apply_coupon_code`,
    params,
  });
}

function loadTaxons() {
  return axios.get('/shop/taxons.json');
}

const api = {
  applyCouponCode,
  loadTaxons,
};

export default api;
