import { SHOPS_ACTION_TYPES } from '../../actions';
import DEFAULT_SELECTORS from '../../constants/selector';

export const initialState = {
  loading: true,
  page: 1,
  entries: [],
  defaultShops: [],
  showOnMap: true,
  viewAllShops: false,
  userAddress: null,
  searchQuery: '',
  states: [],
  selectedState: DEFAULT_SELECTORS.USA,
  directionMode: false,
  directionTo: {},
  selectedTravelMode: 'DRIVING',
  activeStoreId: null,
};

function shops(state = initialState, action = {}) {
  switch (action.type) {
    case SHOPS_ACTION_TYPES.SET_ACTIVE_STORE_ID:
      return { ...state, activeStoreId: action.payload };
    case SHOPS_ACTION_TYPES.SET_PAGE:
      return { ...state, page: action.payload };
    case SHOPS_ACTION_TYPES.SET_TRAVEL_MODE:
      return { ...state, selectedTravelMode: action.payload };
    case SHOPS_ACTION_TYPES.SET_DIRECTION_TO:
      return { ...state, directionTo: action.payload };
    case SHOPS_ACTION_TYPES.SET_DIRECTION_MODE:
      return { ...state, directionMode: action.payload };
    case SHOPS_ACTION_TYPES.SET_SELECTED_STATE:
      return { ...state, selectedState: action.payload };
    case SHOPS_ACTION_TYPES.SET_SEARCH_QUERY:
      return { ...state, searchQuery: action.payload };
    case SHOPS_ACTION_TYPES.SET_USER_ADDRESS:
      return { ...state, userAddress: action.payload };
    case SHOPS_ACTION_TYPES.SHOPS_LOAD_REQUEST:
      return { ...state, loading: true, location: action.payload };
    case SHOPS_ACTION_TYPES.SHOPS_LOADED:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case SHOPS_ACTION_TYPES.INCREMENT_PAGE:
      return { ...state, page: state.page + 1 };
    case SHOPS_ACTION_TYPES.ASSIGN_DEFAULT_DATA:
      return { ...state, ...action.payload.data, defaultShops: action.payload.data.entries };
    case SHOPS_ACTION_TYPES.SET_SHOPS:
      return { ...state, entries: action.payload.entries };
    case SHOPS_ACTION_TYPES.SET_SHOW_ON_MAP:
      return { ...state, showOnMap: action.payload };
    case SHOPS_ACTION_TYPES.TOGGLE_VIEW_ALL_SHOPS:
      if (state.directionMode) { return { ...state, directionMode: false }; }
      return { ...state, viewAllShops: !state.viewAllShops };
    default:
      return state;
  }
}

export default shops;
