const MODALS = {
  SIGN_IN: 'SIGN IN MODAL',
  SIGN_IN_FOR_VOTING: 'SIGN IN FOR VOTING',
  SIGN_IN_FOR_COMMENTING: 'SIGN IN FOR COMMENTING',
  SIGN_IN_FOR_PRODUCT_SUBSCRIPTION: 'SIGN IN FOR PRODUCT SUBSCRIPTION',
  SIGN_UP: 'SIGN UP MODAL',
  SIGN_UP_CONFIRMATION: 'SIGN UP CONFIRMATION MODAL',
  SIGN_UP_CONFIRMATION_FOR_VOTING: 'SIGN UP CONFIRMATION FOR VOTING MODAL',
  SIGN_UP_CONFIRMATION_FOR_COMMENTING: 'SIGN UP CONFIRMATION FOR COMMENTING MODAL',
  SIGN_UP_FOR_VOTING: 'SIGN UP FOR VOTING',
  SIGN_UP_FOR_COMMENTING: 'SIGN UP FOR COMMENTING',
  SIGN_UP_FOR_INNOVATION: 'SIGN UP FOR INNOVATION',
  SIGN_UP_FOR_PRODUCT_SUBSCRIPTION: 'SIGN UP FOR PRODUCT SUBSCRIPTION',
  SIGN_UP_FOR_WAITLIST: 'SIGN UP FOR WAITLIST',
  RESEND_SIGN_UP_CONFIRMATION: 'RESEND SIGN UP CONFIRMATION MODAL',
  RESEND_SIGN_UP_CONFIRMATION_FOR_VOTING: 'RESEND SIGN UP CONFIRMATION FOR VOTING MODAL',
  RESEND_SIGN_UP_CONFIRMATION_FOR_COMMENTING: 'RESEND SIGN UP CONFIRMATION FOR COMMENTING MODAL',
  FORGOT_PASSWORD: 'FORGOT PASSWORD MODAL',
  FORGOT_PASSWORD_FOR_VOTING: 'FORGOT PASSWORD FOR VOTING MODAL',
  FORGOT_PASSWORD_FOR_COMMENTING: 'FORGOT PASSWORD FOR COMMENTING MODAL',
  FORGOT_PASSWORD_FOR_PRODUCT_SUBSCRIPTION: 'FORGOT PASSWORD FOR PRODUCT SUBSCRIPTION MODAL',
  FORGOT_PASSWORD_SENT: 'FORGOT PASSWORD SENT MODAL',
  WELCOME_PROMOTION: 'WELCOME PROMOTION MODAL',
  WELCOME_PROMOTION_FROM_VOTING: 'WELCOME PROMOTION FROM VOTING MODAL',
  CAMPAIGN_NOT_WIN: 'CAMPAIGN NOT WIN MODAL',
  INFORMATION: 'INFORMATION MODAL',
  VOTING: 'VOTING MODAL',
  QUIZ_FINISHED: 'QUIZ FINISHED MODAL',
  INNOVATOR_INFO_SUBMITTED: 'INNOVATOR INFO SUBMITTED',
  REMOVE_CREDIT_CARD: 'REMOVE CREDIT CARD MODAL',
  CANCEL_SUBSCRIPTION: 'CANCEL SUBSCRIPTION',
  IMAGE: 'IMAGE MODAL',
  IMAGE_WITH_ZOOM: 'IMAGE WITH ZOOM MODAL',
  IMAGES_SLIDER: 'IMAGES SLIDER MODAL',
  CAMPAIGN_PASSWORD: 'CAMPAIGN PASSWORD',
  REGIMEN_SENT: 'REGIMEN SENT MODAL',
  REDEEM_GIFT_CARD: 'REDEEM GIFT CARD MODAL',
  GIFT_CARD_REDEEMED: 'GIFT CARD REDEEMED MODAL',
  PURCHASE_GIFT_CARD: 'PURCHASE GIFT CARD MODAL',
  CHECK_OUT_THE_LOUNGE: 'CHECK OUT THE LOUNGE MODAL',
  JOIN_THE_LOUNGE: 'JOIN THE LOUNGE MODAL',
  JOIN_THE_LOUNGE_FOR_VOTING: 'JOIN THE LOUNGE FOR VOTING MODAL',
  JOIN_THE_LOUNGE_FOR_PREORDERING: 'JOIN THE LOUNGE FOR PRE-ORDERING MODAL',
  EXTENDED_SIGN_UP: 'EXTENDED SIGN UP MODAL',
  EXTENDED_SIGN_UP_FOR_VOTING: 'EXTENDED SIGN UP FOR VOTING MODAL',
  FORBIDDEN_INGREDIENTS: 'FORBIDDEN INGREDIENTS MODAL',
  CLOSE_STATUSES: {
    SUCCESSFUL_REGISTRATION: 'SUCCESSFUL REGISTRATION',
    SUCCESSFUL_REGISTRATION_FOR_VOTING: 'SUCCESSFUL REGISTRATION FOR VOTING',
    LOGIN_NOT_CONFIRMED: 'LOGIN NOT CONFIRMED',
    LOGIN_NOT_CONFIRMED_FOR_VOTING: 'LOGIN NOT CONFIRMED FOR VOTING',
    LOGIN_NOT_CONFIRMED_FOR_COMMENTING: 'LOGIN NOT CONFIRMED FOR COMMENTING',
    FORGOT_PASSWORD_SENT: 'FORGOT PASSWORD SENT',
  },
  SCOPES: {
    VOTING: 'VOTING',
    PREORDERING: 'PREORDERING',
    WAITLIST: 'WAITLIST',
    COMMENTING: 'COMMENTING',
    PRODUCT_SUBSCRIPTION: 'PRODUCT SUBSCRIPTION',
  },
};

export default MODALS;
