import { USER_ACTION_TYPES } from '../../actions';
import { ORDER_ACTION_TYPES } from '../../../checkout/actions';
import { LOYALTY_PROGRAM_ACTION_TYPES } from '../../../the_lounge/actions';

export const initialState = {
  accountInformationEditMode: false,
  accountInformationLoading: false,
  addressBookEditMode: false,
  addressBookLoading: false,
  descriptionLoading: false,
  descriptionEditMode: false,
  imageLoading: false,
  newsletterSubscriptionEditMode: false,
  newsletterSubscriptionLoading: false,
  object: null,
  passwordEditMode: false,
  passwordLoading: false,
  paymentEditMode: false,
};

function user(state = initialState, action = {}) {
  switch (action.type) {
    case ORDER_ACTION_TYPES.ORDER_LOADED:
      return { ...state, object: action.payload.user };
    case USER_ACTION_TYPES.SET_USER:
      return { ...state, object: action.payload };
    case USER_ACTION_TYPES.REMOVE_CREDIT_CARD:
      return {
        ...state,
        object: {
          ...state.object,
          payment_sources: state.object.payment_sources.filter(
            (source) => source.id !== action.payload.id,
          ),
        },
      };
    case LOYALTY_PROGRAM_ACTION_TYPES.SET_POINT_BALANCE:
      if (!state.object) { return state; }

      return {
        ...state,
        object: {
          ...state.object,
          point_balance: action.payload,
        },
      };
    case USER_ACTION_TYPES.REMOVE_USER:
      return { ...state, object: null };
    case USER_ACTION_TYPES.SET_ADDRESS_BOOK_EDIT_MODE:
      return { ...state, addressBookEditMode: action.payload };
    case USER_ACTION_TYPES.SET_ADDRESS_BOOK_LOADING:
      return { ...state, addressBookLoading: action.payload };
    case USER_ACTION_TYPES.SET_ACCOUNT_INFORMATION_EDIT_MODE:
      return { ...state, accountInformationEditMode: action.payload };
    case USER_ACTION_TYPES.SET_ACCOUNT_INFORMATION_LOADING:
      return { ...state, accountInformationLoading: action.payload };
    case USER_ACTION_TYPES.SET_DESCRIPTION_EDIT_MODE:
      return { ...state, descriptionEditMode: action.payload };
    case USER_ACTION_TYPES.SET_DESCRIPTION_LOADING:
      return { ...state, descriptionLoading: action.payload };
    case USER_ACTION_TYPES.SET_IMAGE_LOADING:
      return { ...state, imageLoading: action.payload };
    case USER_ACTION_TYPES.SET_NEWSLETTER_SUBSCRIPTION_EDIT_MODE:
      return { ...state, newsletterSubscriptionEditMode: action.payload };
    case USER_ACTION_TYPES.SET_NEWSLETTER_SUBSCRIPTION_LOADING:
      return { ...state, newsletterSubscriptionLoading: action.payload };
    case USER_ACTION_TYPES.SET_PASSWORD_EDIT_MODE:
      return { ...state, passwordEditMode: action.payload };
    case USER_ACTION_TYPES.SET_PASSWORD_LOADING:
      return { ...state, passwordLoading: action.payload };
    case USER_ACTION_TYPES.SET_PAYMENT_EDIT_MODE:
      return { ...state, paymentEditMode: action.payload };
    default:
      return state;
  }
}

export default user;
