import axios from '../../services/axios';
import URLS from '../constants/urls';

function login(params) {
  return axios({
    method: 'POST',
    url: URLS.LOGIN_URL,
    params: {
      user: params,
    },
  });
}

function register(params) {
  return axios({
    method: 'POST',
    url: URLS.REGISTER_URL,
    params: {
      user: params,
    },
  });
}

function facebookAuthCallback(params) {
  return axios({
    method: 'GET',
    url: URLS.FACEBOOK_AUTH_CALLBACK_URL,
    params,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}

function googleAuthCallback(params) {
  return axios({
    method: 'GET',
    url: URLS.GOOGLE_AUTH_CALLBACK_URL,
    params,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}

function resendConfirmationEmail(params) {
  return axios({
    method: 'POST',
    url: URLS.RESEND_CONFIRMATION_EMAIL_URL,
    params: {
      user: params,
    },
  });
}

function resetPassword(params) {
  return axios({
    method: 'POST',
    url: URLS.RESET_PASSWORD_URL,
    params: {
      user: params,
    },
  });
}

function getWelcomePromotion() {
  return axios({
    method: 'GET',
    url: URLS.WELCOME_PROMOTION_URL,
  });
}

function logout() {
  return axios({
    method: 'DELETE',
    url: URLS.LOGOUT_URL,
  });
}

function getGiftCardProducts() {
  return axios({
    method: 'GET',
    url: URLS.GIFT_CARD_PRODUCTS_URL,
  });
}

function addGiftCardToBag(variantId, quantity, giftCardInformation) {
  const params = {
    variant_id: variantId,
    quantity,
    gift_card_information: giftCardInformation,
  };

  return axios({
    method: 'POST',
    url: URLS.ADD_GIFT_CARD_PRODUCT_URL,
    params,
  });
}

const api = {
  facebookAuthCallback,
  getWelcomePromotion,
  googleAuthCallback,
  login,
  logout,
  register,
  resendConfirmationEmail,
  resetPassword,
  getGiftCardProducts,
  addGiftCardToBag,
};

export default api;
