import { CART_ACTION_TYPES, COUPON_CODE_ACTION_TYPES } from '../../actions';
import couponCode, { initialState as couponCodeInitialState } from '../coupon_code';

export const initialState = { active: false, couponCode: couponCodeInitialState };

function cart(state = initialState, action = {}) {
  switch (action.type) {
    case CART_ACTION_TYPES.OPEN_CART:
      return { ...state, active: true };
    case CART_ACTION_TYPES.CLOSE_CART:
      return { ...state, active: false };
    case COUPON_CODE_ACTION_TYPES.SET_ERROR_TEXT:
    case COUPON_CODE_ACTION_TYPES.SET_SUCCESS_TEXT:
    case COUPON_CODE_ACTION_TYPES.APPLY_COUPON_CODE_REQUEST:
      return { ...state, couponCode: couponCode(state.couponCode, action) };
    default:
      return state;
  }
}

export default cart;
