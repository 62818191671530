import { takeEvery, call, put } from 'redux-saga/effects';
import api from '../../api';
import { orderActions, ORDER_ACTION_TYPES } from '../../actions';

export function* loadOrder() {
  const response = yield call(api.loadOrder);
  yield put(orderActions.orderLoaded(response.data));
}

export default function* orderSaga() {
  yield takeEvery(ORDER_ACTION_TYPES.ORDER_LOAD_REQUEST, loadOrder);
  yield takeEvery(ORDER_ACTION_TYPES.ORDER_RELOAD_REQUEST, loadOrder);
}
