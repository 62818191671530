import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  removeErrorMessage: null,
  removeStripeToken: null,
  selectCreditCard: ['payload'],
  selectPaymentMethod: ['payload'],
  setErrorMessage: ['payload'],
  setStripeToken: ['payload'],
  startLoading: null,
  stopLoading: null,
  startLoadingStoreCredit: null,
  stopLoadingStoreCredit: null,
  applyStoreCreditRequest: null,
  removeStoreCreditRequest: null,
  updatePaymentRequest: ['payload'],
}, { prefix: '[PAYMENT] ' });
