import NavigationBlueIcon from '../../../images/icons/navigation-blue.svg';

function buildInfoWindowContent(shop) {
  return `<style>\
      .info-window {\
        display: flex;\
        flex-direction: column;\
        font-family: StyreneB-Light;\
      }\
      .name {\
        font-family: StyreneB-Bold;\
        font-size: 13px;\
      }\
      .address {\
        margin: 8px 0px;\
        font-size: 14px;\
      }\
      .working-hours p {\
        margin: 0px;\
        font-size: 14px;\
      }\
      .directions {\
        display: flex;\
        color: #9DACE4;\
        margin-top: 12px;\
        font-family: StyreneB-Bold;\
        font-size: 13px;\
        cursor: pointer;\
      }\
      .directions-text {\
        margin-left: 8px;\
      }\
    </style>\
    <div class="info-window">\
      <div class="name">\
        ${shop.name}\
      </div>\
      <div class="address">\
        ${shop.full_address}\
      </div>
      <div class="working-hours">\
        ${shop.working_hours}\
      </div>\
      <div class="directions" onclick="window.broadCastDirectionsClick(${shop.id})" >\
        <img alt="navigation blue" src="${NavigationBlueIcon}"/>\
        <span class="directions-text">Directions </span>\
        <span class="distance"> (${shop.distance.toFixed(1)} mi) </span>
      </div>\
    </div>\
    `;
}

export default buildInfoWindowContent;
