import { takeEvery, call, put } from 'redux-saga/effects';
import api from '../../api';
import { taxonsActions, TAXONS_ACTION_TYPES } from '../../actions';

export function* loadTaxons() {
  const response = yield call(api.loadTaxons);
  yield put(taxonsActions.taxonsLoaded(response.data));
}

export default function* taxonsSaga() {
  yield takeEvery(TAXONS_ACTION_TYPES.TAXONS_LOAD_REQUEST, loadTaxons);
}
