import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  startLoading: ['payload'],
  stopLoading: ['payload'],
  startLoadingProduct: ['payload'],
  stopLoadingProduct: ['payload'],
  addToBagRequest: ['payload'],
  addToBagMultipleRequest: ['payload'],
  addProducts: ['payload'],
  setCurrentPage: ['payload'],
  setHasMore: ['payload'],
  loadMoreProductsRequest: ['payload'],
  loadCrossSellsRequest: ['payload'],
  addCrossSells: ['payload'],
  productSubscribeRequest: ['payload'],
  productSubscribed: ['payload'],
}, { prefix: '[PRODUCTS] ' });
