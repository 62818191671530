import { ORDER_ACTION_TYPES } from '../../actions';

export const initialState = { loading: true };

function order(state = initialState, action = {}) {
  switch (action.type) {
    case ORDER_ACTION_TYPES.ORDER_LOAD_REQUEST:
      return { loading: true };
    case ORDER_ACTION_TYPES.ORDER_RELOAD_REQUEST:
      return { ...state, loading: true };
    case ORDER_ACTION_TYPES.ORDER_LOADED:
      return action.payload;
    default:
      return state;
  }
}

export default order;
