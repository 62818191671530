import { MODALS_ACTION_TYPES } from '../../actions';

export const initialState = {
  currentModal: null,
  error: null,
  loading: false,
  data: null,
};

function modals(state = initialState, action = {}) {
  switch (action.type) {
    case MODALS_ACTION_TYPES.OPEN_MODAL:
      return { ...state, currentModal: action.payload };
    case MODALS_ACTION_TYPES.CLOSE_MODAL:
      return { ...state, currentModal: null };
    case MODALS_ACTION_TYPES.SET_ERROR:
      return { ...state, error: action.payload };
    case MODALS_ACTION_TYPES.SET_LOADING:
      return { ...state, loading: action.payload };
    case MODALS_ACTION_TYPES.SET_DATA:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

export default modals;
