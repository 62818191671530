import axios from 'axios';
import qs from 'qs';

const instance = axios.create();
instance.defaults.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'brackets' });
if (typeof document !== 'undefined') {
  const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');
  const token = csrfMetaTag && csrfMetaTag.getAttribute('content');
  instance.defaults.headers.common['X-CSRF-Token'] = token;
}

export default instance;
