import { takeEvery, call, put } from 'redux-saga/effects';
import { stopSubmit, touch } from 'redux-form';
import api from '../../api';
import { onObjectReady } from '../../../checkout/services/utils';
import facebookPixel from '../../../services/facebook_pixel';
import { MODALS_ACTION_TYPES, modalsActions } from '../../actions';
import { orderActions } from '../../../checkout/actions';
import { userActions } from '../../../user/actions';
import MODALS from '../../constants/modals';

function dispatchUserLoggedInEvent(user) {
  const event = new CustomEvent('userLoggedIn', { detail: user });
  document.dispatchEvent(event);
}

export function* setUser(user) {
  yield put(userActions.setUser(user));
  yield put(orderActions.orderLoadRequest());

  if (window.ga) {
    window.ga('set', 'userId', user.id);
  }
  if (user.redirect_url && window.shouldRedirect) {
    window.location.href = user.redirect_url;
  }
  if (user.oauth_redirect_url && window.shouldRedirect) {
    window.location.href = user.oauth_redirect_url;
  }
}

export function* processNotConfirmedLogin(scope) {
  if (scope === MODALS.SCOPES.VOTING) {
    yield put(
      modalsActions.closeModalRequest(MODALS.CLOSE_STATUSES.LOGIN_NOT_CONFIRMED_FOR_VOTING),
    );
  } else if (scope === MODALS.SCOPES.COMMENTING) {
    yield put(
      modalsActions.closeModalRequest(MODALS.CLOSE_STATUSES.LOGIN_NOT_CONFIRMED_FOR_COMMENTING),
    );
  } else {
    yield put(
      modalsActions.closeModalRequest(MODALS.CLOSE_STATUSES.LOGIN_NOT_CONFIRMED),
    );
  }
}

export function* login(action) {
  const { params, scope } = action.payload;
  try {
    yield put(modalsActions.setError(null));
    yield put(modalsActions.setLoading(true));

    const response = yield call(api.login, params);

    const user = response.data;
    yield setUser(user);
    if (user.confirmed_at === null) {
      yield processNotConfirmedLogin(scope);
    } else {
      yield put(modalsActions.closeModal());
    }
    yield put(modalsActions.setLoading(false));

    dispatchUserLoggedInEvent(user);
  } catch (error) {
    yield put(modalsActions.setLoading(false));
    const response = error.response.data;
    yield put(modalsActions.setError(response.error || response.providerError));
  }
}

export function* processSuccessfulRegistration(scope) {
  if (scope === MODALS.SCOPES.VOTING) {
    yield put(
      modalsActions.closeModalRequest(MODALS.CLOSE_STATUSES.SUCCESSFUL_REGISTRATION_FOR_VOTING),
    );
  } else if (scope === MODALS.SCOPES.COMMENTING) {
    yield put(
      modalsActions.closeModalRequest(MODALS.CLOSE_STATUSES.SUCCESSFUL_REGISTRATION_FOR_COMMENTING),
    );
  } else {
    yield put(
      modalsActions.closeModalRequest(MODALS.CLOSE_STATUSES.SUCCESSFUL_REGISTRATION),
    );
  }
}

export function* register(action) {
  const { params, scope } = action.payload;

  try {
    yield put(modalsActions.setError(null));
    yield put(modalsActions.setLoading(true));
    const response = yield call(api.register, params);

    const user = response.data;
    yield setUser(user);
    yield put(modalsActions.setLoading(false));

    yield processSuccessfulRegistration(scope);
    dispatchUserLoggedInEvent(user);
  } catch (error) {
    yield put(modalsActions.setLoading(false));
    const { data } = error.response;
    const errors = {};
    if (data.errors.email) {
      errors.email = `Email ${data.errors.email[0]}`;
    }
    if (data.errors.password) {
      errors.password = `Password ${data.errors.password[0]}`;
    }
    yield put(stopSubmit('signIn', errors));
    yield put(touch('signIn', Object.keys(errors)));

    yield put(stopSubmit('extendedSignUp', errors));
    yield put(touch('extendedSignUp', Object.keys(errors)));
  }
}

export function* setUserFromOauthProvider(user) {
  yield setUser(user);
  if (user.sign_in_count === 1) {
    facebookPixel.multiPurposeTrack('CompleteRegistration');
    yield put(modalsActions.getWelcomePromotionRequest());
  } else {
    yield put(modalsActions.closeModal());
  }
  dispatchUserLoggedInEvent(user);
}

export function* processAuthCallback(url, callbackParams) {
  const response = yield call(url, callbackParams);
  const user = response.data;
  if (!user.id) { throw new Error('Provider login error'); }

  yield setUserFromOauthProvider(user);
}

export function processFacebookLogin() {
  return new Promise((resolve) => {
    onObjectReady(() => window.FB).then(() => {
      window.FB.login(resolve, { scope: 'email' });
    });
  });
}

export function* facebookLogin() {
  yield put(modalsActions.setError(null));
  const facebookLoginResponse = yield call(processFacebookLogin);
  if (!facebookLoginResponse.authResponse) { return; }

  try {
    yield processAuthCallback(api.facebookAuthCallback, {});
  } catch (error) {
    if (!error.response) { return; }

    const { providerError } = error.response.data;
    if (providerError) {
      yield put(modalsActions.setError(providerError));
    }
  }
}

function processGoogleLogin() {
  return new Promise((resolve) => {
    onObjectReady(() => window.gapi).then(() => {
      window.gapi.auth2.authorize({
        client_id: window.gapi.client_id,
        scope: 'email profile',
        response_type: 'code',
        prompt: 'select_account',
      }, resolve);
    });
  });
}

export function* googleLogin() {
  yield put(modalsActions.setLoading(true));
  yield put(modalsActions.setError(null));

  try {
    const googleLoginResponse = yield call(processGoogleLogin);
    if (googleLoginResponse.error) { throw new Error(googleLoginResponse.error); }

    yield processAuthCallback(api.googleAuthCallback, { code: googleLoginResponse.code });
    yield put(modalsActions.setLoading(false));
  } catch (error) {
    yield put(modalsActions.setLoading(false));

    if (!error.response) { return; }

    const { providerError } = error.response.data;
    if (providerError) {
      yield put(modalsActions.setError(providerError));
    }
  }
}

export function* resendEmailConfirmation(action) {
  const { params } = action.payload;

  try {
    yield put(modalsActions.setLoading(true));
    yield call(api.resendConfirmationEmail, { email: params });
    yield put(modalsActions.setLoading(false));
    yield put(modalsActions.closeModal());
  } catch (error) {
    yield put(modalsActions.setLoading(false));
  }
}

export function* resetPassword(action) {
  const { params } = action.payload;

  try {
    yield put(modalsActions.setLoading(true));
    yield call(api.resetPassword, params);
    yield put(modalsActions.setLoading(false));
    yield put(
      modalsActions.closeModalRequest(MODALS.CLOSE_STATUSES.FORGOT_PASSWORD_SENT),
    );
  } catch (error) {
    yield put(modalsActions.setLoading(false));
    const { data } = error.response;
    const errors = {};
    if (data.errors.email) {
      errors.email = `Email ${data.errors.email[0]}`;
    }
    yield put(stopSubmit('email', errors));
    yield put(touch('email', Object.keys(errors)));
  }
}

export function* showWelcomePromotion() {
  try {
    yield put(modalsActions.setLoading(true));
    const response = yield call(api.getWelcomePromotion);
    const { code, expires_at: expiresAt } = response.data;
    yield put(modalsActions.setLoading(false));
    yield put(
      modalsActions.openModalRequest({
        modal: MODALS.WELCOME_PROMOTION,
        data: { code, expiresAt },
      }),
    );
  } catch (error) {
    yield put(modalsActions.setLoading(false));
  }
}

export function* logout() {
  yield call(api.logout);
  yield put(userActions.setUser(null));
  yield put(orderActions.orderLoadRequest());

  const event = new Event('userLoggedOut');
  document.dispatchEvent(event);

  if (window.ga) {
    window.ga('userId.remove');
  }
  if (window.location.pathname.match(/users.\d+/)) {
    window.location.href = '/shop/';
  }
}

export default function* loginSaga() {
  yield takeEvery(MODALS_ACTION_TYPES.LOGIN_REQUEST, login);
  yield takeEvery(MODALS_ACTION_TYPES.REGISTER_REQUEST, register);
  yield takeEvery(MODALS_ACTION_TYPES.FACEBOOK_LOGIN_REQUEST, facebookLogin);
  yield takeEvery(MODALS_ACTION_TYPES.GOOGLE_LOGIN_REQUEST, googleLogin);
  yield takeEvery(
    MODALS_ACTION_TYPES.RESEND_CONFIRMATION_EMAIL_REQUEST,
    resendEmailConfirmation,
  );
  yield takeEvery(
    MODALS_ACTION_TYPES.RESET_PASSWORD_REQUEST,
    resetPassword,
  );
  yield takeEvery(
    MODALS_ACTION_TYPES.GET_WELCOME_PROMOTION_REQUEST,
    showWelcomePromotion,
  );
  yield takeEvery(MODALS_ACTION_TYPES.LOGOUT_REQUEST, logout);
}
