import { takeEvery, call, put } from 'redux-saga/effects';
import api from '../api';
import mapServices from '../services/mapService';
import { shopsActions, SHOPS_ACTION_TYPES } from '../actions';

export function* loadShops(action) {
  const { location } = action.payload;
  const response = yield call(api.loadShops, location);
  yield put(shopsActions.shopsLoaded(response.data));
  yield call(mapServices.reinitMapMarkers, response.data.entries);
}

export default function* shopsSaga() {
  yield takeEvery(SHOPS_ACTION_TYPES.SHOPS_LOAD_REQUEST, loadShops);
}
