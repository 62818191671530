import { LINE_ITEMS_ACTION_TYPES } from '../../actions';

export const initialState = {};

function lineItem(state = initialState, action = {}) {
  switch (action.type) {
    case LINE_ITEMS_ACTION_TYPES.START_LOADING:
      if (action.payload.id !== state.id) { return state; }
      return { ...state, loading: true };
    case LINE_ITEMS_ACTION_TYPES.STOP_LOADING:
      if (action.payload.id !== state.id) { return state; }
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default lineItem;
