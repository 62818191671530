import { takeEvery, call, put } from 'redux-saga/effects';
import api from '../../api';
import { couponCodeActions, COUPON_CODE_ACTION_TYPES } from '../../actions';
import { orderActions } from '../../../checkout/actions';

export function* applyCouponCode(action) {
  const { orderNumber, code, guestToken } = action.payload;

  try {
    const response = yield call(api.applyCouponCode, orderNumber, code, guestToken);
    yield put(couponCodeActions.setSuccessText(response.data.success));
    yield put(orderActions.orderLoadRequest());
  } catch (error) {
    yield put(couponCodeActions.setErrorText(error.response.data.error));
  }
}

export default function* couponCodeSaga() {
  yield takeEvery(COUPON_CODE_ACTION_TYPES.APPLY_COUPON_CODE_REQUEST, applyCouponCode);
}
