import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import bestSellers from '../../homepage/reducers/best_sellers';
import taxons from './taxons';
import cart from './cart';
import search from './search';
import upSells from './up_sells';
import order from '../../checkout/reducers/order';
import lineItems from '../../checkout/reducers/line_items';
import freeSamples from '../../checkout/reducers/free_samples';
import payment from '../../checkout/reducers/payment';
import user from '../../user/reducers/user';
import products from '../../products/reducers/products';
import campaigns from '../../campaigns/reducers/campaigns';
import modals from '../../modals/reducers/modals';
import reviews from '../../product/reducers/reviews';
import shops from '../../shops/reducers/shops';
import loyaltyProgram from '../../the_lounge/reducers/loyalty_program';
import innovatorHighlights from '../../innovator/reducers/innovator_highlights';
import shipping from '../../checkout/reducers/shipping';

const rootReducer = combineReducers({
  form: formReducer,
  bestSellers,
  taxons,
  cart,
  search,
  order,
  shipping,
  lineItems,
  freeSamples,
  user,
  products,
  modals,
  reviews,
  campaigns,
  shops,
  payment,
  upSells,
  loyaltyProgram,
  innovatorHighlights,
});

export default rootReducer;
