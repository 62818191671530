import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  startLoadingCampaign: ['payload'],
  stopLoadingCampaign: ['payload'],
  voteRequest: ['payload'],
  vote: ['payload'],
  increaseVotesCount: ['payload'],
  loadCampaignsRequest: null,
  setCampaigns: ['payload'],
  setCategories: ['payload'],
  setCampaignsByCategory: ['payload'],
  setSelectedCategoryId: ['payload'],
  selectVariantId: ['payload'],
  upvote: ['payload'],
  downvote: ['payload'],
  upvoteRequest: ['payload'],
  downvoteRequest: ['payload'],
}, { prefix: '[CAMPAIGNS] ' });
