import axios from '../../services/axios';

function addToBag(variantId, quantity, frequency) {
  const params = {
    variant_id: variantId,
    quantity,
  };

  if (frequency || frequency === 0) {
    params.options = { frequency };
  }

  return axios({
    method: 'POST',
    url: '/shop/orders/populate.json',
    params,
  });
}

function addToBagMultiple(variantIds, couponCode) {
  const params = {
    variant_ids: variantIds,
    coupon_code: couponCode,
  };

  return axios({
    method: 'POST',
    url: '/shop/orders/populate_multiple.json',
    params,
  });
}

function loadMoreProducts(url) {
  return axios({
    method: 'GET',
    url,
  });
}

function loadCrossSells(productId) {
  return axios({
    headers: { Accept: 'application/json' },
    method: 'GET',
    url: `/shop/products/${productId}/cross_sells`,
  });
}

function loadProductsByIds(productIds) {
  return axios({
    headers: { Accept: 'application/json' },
    method: 'GET',
    url: '/shop/products_by_ids',
    params: { ids: productIds },
  });
}

function subscribe(subscriptionParams) {
  const params = {
    subscription: subscriptionParams,
  };

  return axios({
    method: 'POST',
    url: '/subscriptions',
    params,
  });
}

const api = {
  addToBag,
  addToBagMultiple,
  loadMoreProducts,
  loadCrossSells,
  subscribe,
  loadProductsByIds,
};

export default api;
