import { COUPON_CODE_ACTION_TYPES } from '../../actions';

export const initialState = { errorText: null, successText: null };

function couponCode(state = initialState, action = {}) {
  switch (action.type) {
    case COUPON_CODE_ACTION_TYPES.SET_ERROR_TEXT:
      return { successText: null, errorText: action.payload };
    case COUPON_CODE_ACTION_TYPES.SET_SUCCESS_TEXT:
      return { errorText: null, successText: action.payload };
    case COUPON_CODE_ACTION_TYPES.APPLY_COUPON_CODE_REQUEST:
      return initialState;
    default:
      return state;
  }
}

export default couponCode;
