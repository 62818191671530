import axios from '../../services/axios';

function loadMoreInnovatorHighlights(url) {
  return axios({
    method: 'GET',
    url,
  });
}

const api = { loadMoreInnovatorHighlights };

export default api;
