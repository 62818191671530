const URLS = {
  CHECKOUT_PATH: '/shop/checkout_now',
  ORDER_PATH: '/shop/checkout_now/order.json',
  UPDATE_SHIPPING_ADDRESS_PATH: '/shop/checkout_now/update_shipping_address.json',
  UPDATE_SHIPPING_METHOD_PATH: '/shop/checkout_now/update_shipping_method.json',
  UPDATE_PAYMENT_PATH: '/shop/checkout_now/update_payment.json',
  UPDATE_CART_PATH: '/shop/cart.json',
  API_PATH: '/shop/api/v1/orders/',
  EMAIL_UPDATE_PATH: '/email_update',
  AMAZON_PAYMENT_SELECT_PATH: '/amazon_order/payment',
  AMAZON_PAYMENT_CONFIRM_PATH: '/amazon_order/confirm.json',
  SHOP_PATH: '/shop',
  ADD_FREE_SAMPLE_PATH: '/shop/free_samples/add.json',
  REMOVE_FREE_SAMPLE_PATH: '/shop/free_samples/remove.json',
  APPLY_STORE_CREDIT_PATH: '/shop/checkout_now/apply_store_credit.json',
  REMOVE_STORE_CREDIT_PATH: '/shop/checkout_now/remove_store_credit.json',
};

export default URLS;
