export const onObjectReady = (objectRetrieveFunction) => (
  new Promise((resolve) => {
    const interval = setInterval(() => {
      const object = objectRetrieveFunction();
      if (object) {
        clearInterval(interval);
        resolve(object);
      }
    }, 100);
  })
);

export const numberToCurrency = (numberToFormat, currency) => {
  const number = Number(numberToFormat);

  if (!currency || currency === 'USD') {
    return numberToCurrency(number, '$');
  }

  if (currency === 'GBP') {
    return numberToCurrency(number, '£');
  }

  const sign = number < 0 ? '-' : '';

  return `${sign}${currency}${Math.abs(number).toFixed(2)}`;
};

export const elementClicked = (event, element) => {
  if (!element) { return false; }

  const {
    x, y, width, height,
  } = element.getBoundingClientRect();

  if (event.clientX >= x && event.clientX <= x + width
      && event.clientY >= y && event.clientY <= y + height) {
    return true;
  }

  return false;
};
