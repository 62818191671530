import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  startLoading: ['payload'],
  stopLoading: ['payload'],
  addInnovatorHighlights: ['payload'],
  setCurrentPage: ['payload'],
  setInnovatorHighlightsTotal: ['payload'],
  loadMoreInnovatorHighlightsRequest: ['payload'],
}, { prefix: '[INNOVATOR HIGHLIGHTS] ' });
