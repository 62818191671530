import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  creditCardRemoveRequest: ['payload'],
  removeCreditCard: ['payload'],
  removeUser: null,
  setAccountInformationEditMode: ['payload'],
  setAccountInformationLoading: ['payload'],
  setAddressBookEditMode: ['payload'],
  setAddressBookLoading: ['payload'],
  setDescriptionEditMode: ['payload'],
  setDescriptionLoading: ['payload'],
  setImageLoading: ['payload'],
  setNewsletterSubscriptionEditMode: ['payload'],
  setNewsletterSubscriptionLoading: ['payload'],
  setPasswordEditMode: ['payload'],
  setPasswordLoading: ['payload'],
  setPaymentEditMode: ['payload'],
  setUser: ['payload'],
  updateAccountInformationRequest: ['payload'],
  updateAddressRequest: ['payload'],
  updateDescriptionRequest: ['payload'],
  updateNewsletterSubscriptionRequest: ['payload'],
  updateImageRequest: ['payload'],
  updatePasswordRequest: ['payload'],
}, { prefix: '[USER] ' });
