import { takeEvery } from 'redux-saga/effects';
import { CART_ACTION_TYPES } from '../../actions';

const mobileScreenWidth = 540;

export function openCartRequest() {
  if (window.innerWidth < mobileScreenWidth) {
    window.scrollService.disableScroll();
    const cartWrapper = document.querySelector('.cart-wrapper');
    if (cartWrapper) { // CartWrapper may be not loaded
      cartWrapper.style.height = `${window.innerHeight}px`;
    }
  }
}

export function closeCartRequest() {
  window.scrollService.enableScroll();
}

function redirectToShop() {
  window.location.href = '/shop/checkout_now/';
}

function trackCheckoutStepAndRedirectToShop() {
  window.dataLayer.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step: 1,
        },
      },
    },
    eventCallback() {
      redirectToShop();
    },
    eventTimeout: 3000,
  });

  // window.dataLayer can be an array, so eventCallback is never executed
  setTimeout(redirectToShop, 3000);
}

export function goToCheckout() {
  if (window.dataLayer) {
    trackCheckoutStepAndRedirectToShop();
  } else {
    redirectToShop();
  }
}

export default function* cartSaga() {
  yield takeEvery(CART_ACTION_TYPES.OPEN_CART, openCartRequest);
  yield takeEvery(CART_ACTION_TYPES.CLOSE_CART, closeCartRequest);
  yield takeEvery(CART_ACTION_TYPES.CHECKOUT_REQUEST, goToCheckout);
}
