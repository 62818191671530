import { LOYALTY_PROGRAM_ACTION_TYPES } from '../../actions';

export const initialState = {
  pointMovements: [],
  pointsEarnedForYear: 0,
  tiers: [],
};

function loyaltyProgram(state = initialState, action = {}) {
  switch (action.type) {
    case LOYALTY_PROGRAM_ACTION_TYPES.SET_POINT_MOVEMENTS:
      return { ...state, pointMovements: action.payload };
    case LOYALTY_PROGRAM_ACTION_TYPES.SET_POINTS_EARNED_FOR_YEAR:
      return { ...state, pointsEarnedForYear: action.payload };
    case LOYALTY_PROGRAM_ACTION_TYPES.SET_TIERS:
      return { ...state, tiers: action.payload };
    default:
      return state;
  }
}

export default loyaltyProgram;
