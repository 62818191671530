import { BEST_SELLERS_ACTION_TYPES } from '../../actions';
import { PRODUCTS_ACTION_TYPES } from '../../../products/actions';
import bestSellerReducer from '../best_seller';

export const initialState = [];

function bestSellers(state = initialState, action = {}) {
  switch (action.type) {
    case BEST_SELLERS_ACTION_TYPES.SET_BEST_SELLERS:
      return action.payload;
    case PRODUCTS_ACTION_TYPES.START_LOADING_PRODUCT:
    case PRODUCTS_ACTION_TYPES.STOP_LOADING_PRODUCT:
      return state.map((product) => bestSellerReducer(product, action));
    default:
      return state;
  }
}

export default bestSellers;
