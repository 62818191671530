import { takeEvery, call, put } from 'redux-saga/effects';
import api from '../../api';
import { PRODUCTS_ACTION_TYPES, productsActions } from '../../actions';
import { lineItemsActions, orderActions } from '../../../checkout/actions';
import { cartActions } from '../../../cart/actions';

export function* addProduct(action) {
  function* onRequestComplete(response) {
    if (Object.keys(response.errors).length > 0) {
      const [[error]] = Object.values(response.errors);
      yield put(lineItemsActions.setError(error));
    }

    yield put(orderActions.orderLoaded(response));
    yield put(productsActions.stopLoadingProduct({ id: action.payload.id }));
    yield put(cartActions.openCart());
  }

  yield put(productsActions.startLoadingProduct({ id: action.payload.id }));
  try {
    const response = yield call(
      api.addToBag,
      action.payload.variantId,
      1,
      action.payload.frequency,
    );
    yield* onRequestComplete(response.data);
  } catch (error) {
    yield put(productsActions.stopLoadingProduct({ id: action.payload.id }));
  }
}

export function* addToBagMultiple(action) {
  function* onRequestComplete(response) {
    if (Object.keys(response.errors).length > 0) {
      const [[error]] = Object.values(response.errors);
      yield put(lineItemsActions.setError(error));
    }

    yield put(orderActions.orderLoaded(response));

    for (let i = 0; i < action.payload.ids.length; i += 1) {
      yield put(productsActions.stopLoadingProduct({ id: action.payload.ids[i] }));
    }

    yield put(productsActions.stopLoading());
    yield put(cartActions.openCart());
  }

  yield put(productsActions.startLoading());

  for (let i = 0; i < action.payload.ids.length; i += 1) {
    yield put(productsActions.startLoadingProduct({ id: action.payload.ids[i] }));
  }

  const response = yield call(
    api.addToBagMultiple,
    action.payload.variantIds,
    action.payload.couponCode,
  );
  yield* onRequestComplete(response.data);
}

export function* loadMoreProducts(action) {
  function* onRequestComplete(response) {
    if (response.length < action.payload.productsPerPage) {
      yield put(productsActions.setHasMore(false));
    }

    yield put(productsActions.addProducts(response));
    yield put(productsActions.stopLoading());
    yield put(productsActions.setCurrentPage(action.payload.currentPage + 1));
  }

  yield put(productsActions.startLoading());
  const response = yield call(api.loadMoreProducts, action.payload.url);
  yield* onRequestComplete(response.data);
}

export function* loadCrossSells(action) {
  function* onRequestComplete(response) {
    yield put(productsActions.addProducts(response));
    yield put(productsActions.addCrossSells(response));
  }

  const response = yield call(api.loadCrossSells, action.payload);
  yield* onRequestComplete(response.data);
}

export function* subscribe(action) {
  const { productId, email } = action.payload;

  function* onRequestComplete() {
    yield put(productsActions.productSubscribed({ id: productId }));
    yield put(productsActions.stopLoadingProduct({ id: productId }));
  }

  yield put(productsActions.startLoadingProduct({ id: productId }));
  yield call(api.subscribe, { product_id: productId, email });
  yield* onRequestComplete();
}

export default function* productsSaga() {
  yield takeEvery(PRODUCTS_ACTION_TYPES.ADD_TO_BAG_REQUEST, addProduct);
  yield takeEvery(PRODUCTS_ACTION_TYPES.ADD_TO_BAG_MULTIPLE_REQUEST, addToBagMultiple);
  yield takeEvery(PRODUCTS_ACTION_TYPES.LOAD_MORE_PRODUCTS_REQUEST, loadMoreProducts);
  yield takeEvery(PRODUCTS_ACTION_TYPES.LOAD_CROSS_SELLS_REQUEST, loadCrossSells);
  yield takeEvery(PRODUCTS_ACTION_TYPES.PRODUCT_SUBSCRIBE_REQUEST, subscribe);
}
