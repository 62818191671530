const PAYMENT_CONSTANTS = {
  METHODS: {
    PAYPAL: 'Spree::Gateway::PayPalExpress',
    CREDIT_CARD: 'Spree::Gateway::StripeGateway',
    EXISTING_CREDIT_CARD: 'EXISTING_CREDIT_CARD',
    AMAZON_PAY: 'AMAZON_PAY',
    QUADPAY: 'Spree::BillingIntegration::QuadPayCheckout',
    STORE_CREDIT: 'STORE_CREDIT',
  },
  HUMAN_PAYMENT_METHOD_NAMES: {
    'Spree::Gateway::PayPalExpress': 'Paypal',
    'Spree::Gateway::StripeGateway': 'Credit Card',
    EXISTING_CREDIT_CARD: 'Credit Card',
    AMAZON_PAY: 'AmazonPay',
    'Spree::BillingIntegration::QuadPayCheckout': 'QuadPay',
  },
  QUADPAY_PAYMENTS_COUNT: 4,
  PAYMENT_STEP_NUMBER: 3,
};

export default PAYMENT_CONSTANTS;
