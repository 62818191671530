import { FREE_SAMPLES_ACTION_TYPES, ORDER_ACTION_TYPES } from '../../actions';
import freeSampleReducer from './free_sample';

export const initialState = {
  objects: [],
  loading: false,
};

function freeSamples(state = initialState, action = {}) {
  switch (action.type) {
    case FREE_SAMPLES_ACTION_TYPES.SELECT:
    case FREE_SAMPLES_ACTION_TYPES.UNSELECT:
      return {
        ...state,
        objects: state.objects.map((lineItem) => freeSampleReducer(lineItem, action)),
      };
    case ORDER_ACTION_TYPES.ORDER_LOADED:
      return {
        ...state,
        objects: action.payload.free_samples,
      };
    default:
      return state;
  }
}

export default freeSamples;
