import { takeEvery, call, put } from 'redux-saga/effects';
import api from '../../api';
import { FREE_SAMPLES_ACTION_TYPES, freeSamplesActions } from '../../actions';

export function* addFreeSample(action) {
  yield put(freeSamplesActions.select({ id: action.payload.id }));

  try {
    yield call(api.addFreeSample, { id: action.payload.id });
  } catch (error) {
    yield put(freeSamplesActions.unselect({ id: action.payload.id }));
  }
}

export function* removeFreeSample(action) {
  yield put(freeSamplesActions.unselect({ id: action.payload.id }));

  try {
    yield call(api.removeFreeSample, { id: action.payload.id });
  // eslint-disable-next-line no-empty
  } catch (error) {}
}

export default function* freeSamplesSaga() {
  yield takeEvery(FREE_SAMPLES_ACTION_TYPES.ADD_FREE_SAMPLE_REQUEST, addFreeSample);
  yield takeEvery(FREE_SAMPLES_ACTION_TYPES.REMOVE_FREE_SAMPLE_REQUEST, removeFreeSample);
}
