import { FREE_SAMPLES_ACTION_TYPES } from '../../../actions';

export const initialState = {};

function freeSample(state = initialState, action = {}) {
  switch (action.type) {
    case FREE_SAMPLES_ACTION_TYPES.SELECT:
      return { ...state, selected: action.payload.id === state.id };
    case FREE_SAMPLES_ACTION_TYPES.UNSELECT:
      if (action.payload.id !== state.id) { return state; }
      return { ...state, selected: false };
    default:
      return state;
  }
}

export default freeSample;
